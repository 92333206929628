#detail-product{
  padding:20px 0;
    ol.breadcrumb{
      background-color: transparent;
      margin-bottom:0;
      min-height: 40px;
      align-items: center;
      padding: 0;
      li, li.active{
        @include textFormatting('Roboto-Regular', 10pt, $mainGray, uppercase, default);
      }
      li a{
        @include textFormatting('Roboto-Regular', 10pt, $mainGray, uppercase, pointer);
      }

      @media only screen and (max-width: 991.98px) {
        justify-content: center;
      }
    }
    .color-line {
      border-top:1px solid $mainOrange;
      margin:0;
    }

  .info-category{
    .category-product{
      @include textFormatting('Roboto-Bold', 10pt, $mainGray, uppercase, default);
    }
    .content-info{
      display: flex;
      justify-content: space-between;
      padding-bottom: 0px;
      .out-semaphore{
        width: fit-content;
      }
      .second-block-info{
        margin: auto 0;
      }
    }
    .shipping-info{
      margin: 5px 0;
      color: #8e9818;
    }
    .iconography{
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom:0.5rem;
      grid-gap: 0.35rem;
      img{
        width: 30px;
        height: 30px;
        object-fit: cover;
      }
    }
    .name-product{
      @include textFormatting('Poppins-Bold', 17pt, $mainGray, none, default);
      margin: 0;
    }
    .current-price-product{
      @include textFormatting('Roboto-Slab-Black', 17pt, $mainOrange, none, default);
    }
    .old-price-product{
      @include textFormatting('Roboto-Slab-Black', 12pt, $darkGray, none, default);
      text-decoration: line-through;
      text-align: right;
      margin: 0;
    }
  }

  .option-to-buy-this-product{
    display: flex;
    border-top:1px solid $mainOrange;
    padding: 20px 0;
    align-items: center;
    .number-input{
      height:35px;
    }
    .product-delivered-in-store{
      padding: 0 5px;
      font-size: 14px;
      margin: 0 1rem;
      i{
        font-size: 18px;
        padding-right: 0.25rem;
      }
    }
    .add-to-cart{
      padding: 0.5rem 1rem;
      width: 160px;
      background-color: $mainOrange;
      @include textFormatting('Poppins-Bold', 12pt, $White, uppercase, pointer);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  }

  .content-category{
    border-top:1px solid $mainOrange;
    border-bottom:1px solid $mainOrange;
    padding:20px 0;
    @include textFormatting('Poppins-Regular', 12pt, $mainGray, none, default);
    p {
      margin:0;
    }
  }

  .share-product{
    height:40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding-bottom:5px;
    .like-in-product{
      width: 27px;
      cursor: pointer;
    }
    .share-icon{
      width: 20px;
      margin: 0 10px;
      cursor: pointer;
    }
    .option-to-share{
      visibility:hidden;
      display: flex;
      align-items: center;
      padding: 10px 0 0 0;
    }
    .social_share i{
      font-size: 16px;
      color: $mainGray;
      &:hover{
        color: $mainOrange;
      }
    }
  }

  .related-products{
    .section-title{
      @include textFormatting('Poppins-Bold', 17pt, $mainOrange, uppercase, default);
      text-align: left;
      padding-top:20px;
    }
  }

  .body-additional-product{
    background-color: $lightGray;
  }
 .additional-products{
  .section-title{
    @include textFormatting('Poppins-Bold', 17pt, $mainOrange, uppercase, default);
    text-align: left;
    padding-top:20px;
  }

  .list-of-additional-products{
    display: grid;
    align-items: start;
    grid-gap: 0 20px;
  }
}

  @media only screen and (min-width: 992px) {
    .product-presentation{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
      margin: 20px 0 40px 0;
    }

    .info-for-mobile{ display: none; }

    .info-category{
      .info-name{
        display: flex;
        align-items: center;
      }
    }
    .additional-products{
      .list-of-additional-products{
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  .product-success{
    margin: 0 20px;
    @include textFormatting('Roboto-Regular', 10pt, $Green, none, default);
  }

  @media only screen and (min-width: 768px)  and (max-width: 991.98px) {
    .product-presentation{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
      margin: 20px 0;
    }

    .info-for-mobile{ display: none; }

    .info-category{
      width:85%;
      .info-name{
        display: block;
      }
    }
  }

  @media only screen and (max-width: 767.98px) {
    .product-presentation{
      display: flex;
      flex-direction: column;
      margin: 20px 0 40px 0;
      .content-category, .share-product{ display:none;}
    }

    .info-for-mobile{
      display: block;
      .like-in-product{ width:24px; }
      .content-category{ margin-top:40px; }
    }

    .slick-slider-product{
      order:2;
    }
    .info-category{
      order:1;
      .info-name{
        display: block;
      }
    }
  }
}
