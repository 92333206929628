.slick-slider-product {
  .type-of-condition {
    @import "./../product-card-components/sales-condition-type";
    position: absolute;

    .sale-type-block {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  #slider-nav img {
    border: 1px solid $darkGray;
    cursor: pointer;
    width: 80px;
  }

  #slider-image {
    border: 1px solid $darkGray;
  }

  .img-slider-image {
    width: 100%;
  }

  .no-image {
    width: 100%;
    height: 420px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media only screen and (max-width: 991.98px) and (min-width: 768px) {
      height: 360px;
    }
    @media only screen and (max-width: 767.98px) {
      height: 220px;
    }
  }

  .no-image-thumbnail {
    width: 80px;
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid $darkGray;
  }
}

.open-in-fullscreen {
  width: 20px;
  position: absolute;
  bottom: 20px;
  right: 15px;
  cursor: pointer;
}

@media only screen and (min-width: 1200px) {
  .slick-slider-product {
    width: 500px;
    max-height: 500px;
    display: flex;
    align-items: center;

    #slider-nav {
      width: 100px;

      span.slick-prev.slick-arrow {
        transform: rotate(90deg);
        display: flex;
        align-items: center;
        height: 20px;
        width: 100%;
        margin: 10px auto;
        justify-content: center;
        cursor: pointer;
      }

      .slick-list {
        min-width: 400px !important;
        min-height: 400px;
        padding: 0 !important;
      }
    }

    #slider-image {
      width: 400px;
      height: 400px;

      video {
        width: 400px;
        height: 400px;
      }

      .youtube-iframe {
        object-fit: cover;
        margin: auto;
        display: block;
        width: 400px;
        height: 400px;
        border: none;
      }
    }
  }
}

@media only screen and (max-width: 1199.98px) and (min-width: 768px) {
  .slick-slider-product {
    width: 400px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 991.98px) and (min-width: 768px) {
      width: 320px;
    }

    #slider-nav {
      margin-top: 20px;
      width: 100%;
      order: 2;
      align-items: center;
      display: flex;
      justify-content: flex-start;

      span.slick-prev.slick-arrow {
        margin: auto 5px;
      }

      img {
        width: 60px;
      }

      .slick-track {
        min-width: 400px !important;
      }

      @media only screen and (max-width: 991.98px) and (min-width: 768px) {
        .slick-track {
          min-width: 300px !important;
        }

      }
    }

    #slider-image {
      width: 100%;
      max-height: 320px;
      order: 1;

      img {
        width: 100%;
      }

      .youtube-iframe {
        object-fit: cover;
        margin: auto;
        display: block;
        width: 400px;
        height: 320px;
        border: none;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .slick-slider-product {
    min-width: 280px;
    width: 100%;
    display: flex;
    flex-direction: column;

    #slider-nav {
      width: 100%;
      order: 2;
      align-items: center;
      display: flex;
      margin-top: 10px;

      span.slick-prev.slick-arrow {
        margin: auto 5px;
      }

      /*img {
        width: 70px;
      }*/

      .slick-track {
        min-width: 400px !important;
      }

    }

    #slider-image {
      width: 100%;
      order: 1;

      img {
        width: 100%;
      }

      .youtube-iframe {
        object-fit: cover;
        margin: auto;
        display: block;
        min-width: 280px;
        width: 100%;
        height: 100vh;
        border: none;
        max-height: 500px;
      }
    }
  }
}

