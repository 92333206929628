#novelty-block{
  .line-through {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 767.98px) {
      flex-direction: column;
    }
    &:after{
      content:'';
      width: 100%;
      height: 1px;
      background-color: $mainOrange;
      position: absolute;
      @media only screen and (max-width: 767.98px) {
        display:none;
      }
    }
    .anchor-for-section{
      background-color: $mainOrange;
      @media only screen and (min-width: 768px) {
        width: calc(100% / 3);
      }
      @media only screen and (max-width: 767.98px) {
        width:100%;
      }
      height: 45px;
      @include textFormatting('Poppins-Bold', 15pt, $White, uppercase, pointer);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      scroll-behavior: smooth;
      &:nth-child(2){
        @media only screen and (min-width: 768px) {
          border-left: 2px solid $White;
          border-right: 2px solid $White;
        }
        @media only screen and (max-width: 767.98px) {
         margin: 5px 0;
        }
      }
    }
  }
  .title-section{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    span{
      @include textFormatting('Poppins-Bold', 14pt, $mainOrange, uppercase, default);
    }
  }

  #nav-promotions-section{
    background-color: $bgLightGray;
  }

  @media only screen and (max-width: 767.98px) {
    .lastest-news .info-article{
      margin-bottom:20px;
    }
  }
}
