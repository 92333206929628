.product-buy-block{
  height:30px;
  display: flex;
  justify-content: center;
  div.basket-anchor {
    width: 40px;
   // margin-left: 10px;
    border: 1px solid $darkGray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon-basket {
      width: 20px;
    }
  }

  .like-in-product{
    width: 27px;
    cursor: pointer;
  }
}


