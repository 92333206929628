.menu-icon {
  width: 20px;
  height: 20px;
  display: grid;
  cursor: pointer;

  & span {
    background-color: $darkGray;
    width: 100%;
    height: 2px;
  }

  &:not(:last-of-type) {
    margin-bottom: 3px;
  }
}
