.recovery-password-page {
  min-height: calc(100vh - 541px);
  display: flex;
  align-items: center;
  justify-content: center;
  .card, .card-header, .card-body {
    border: 0;
    background-color: $White;
    align-items: center;
  }

  .card-header {
    text-align: center;

    span {
      @include textFormatting('Poppins-Bold', 17pt, $mainOrange, none, default);
    }
  }

  .card-body {
    width: 350px;
    .phrase-page{
      @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
    }
    .form-group{
      margin:20px 0;
    }
  }
  .form-label{
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
    margin-bottom: 3px;
  }
  .form-control{
    height: 40px;
    margin:0;
    text-align: left;
    &:focus{
      box-shadow: 0 0 0 0.2rem rgb(213 122 35 / 25%);
      border-color: $mainOrange;
    }
  }

  button[type=submit]{
    width: fit-content;
    height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $mainOrange;
    border-color: $mainOrange;
    @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
    &:focus{
      outline: 0;
    }
    &:hover{
      box-shadow: 0 0 0 0.2rem rgb(154 155 158 / 25%);
      border-color: $darkGray;
      color: $mainGray;
    }
  }
}
