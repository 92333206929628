.mburger {
  width: 50px;
  --mb-button-size: 50px;
  --mb-bar-height: 2px;
  --mb-bar-color: $darkGray;
  --mb-bar-spacing: 7px;

  b {
    background-color: $darkGray !important;
  }

  &:hover {
    b {
      background-color: $mainOrange !important;
    }
  }
}

#vertical-navbar {
  --mm-color-background: #3B3B3BFF;
  --mm-color-border: transparent;
  --mm-color-button: #fff;
  --mm-color-background-emphasis: transparent;

  .mm-panels .mm-panel:first-of-type .mm-navbar_sticky {
    display: none;
  }

  .mm-panel:after {
    display: none;
  }
}

.menu-separator-mob {
  height: 60px;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  #vertical-navbar {
    width: calc(100% / 2);
    max-width: 495px;
    min-width: 300px;
  }
}

@media only screen and (max-width: 991.98px) {
  #vertical-navbar {
    width: 100%;
    max-width: 990px;
    min-width: 300px;
  }
}

#vertical-navbar {
  .mm-navbar_sticky, .mm-btn_prev {
    @include textFormatting('Poppins-Regular', 12pt, $White, uppercase, pointer);
    &:hover {
      color: $mainOrange;
    }
  }

  .mm-btn_next {
    @include textFormatting('Poppins-Regular', 14pt, $White, uppercase, pointer);
    align-items: center;
    display: flex;
    margin-top: 15px;
    &:hover {
      color: $mainOrange;
    }
  }

  .mm-btn:after, .mm-btn:before {
    border-color: $White;
  }

  li {
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;

    .anchor-menu-item {
      padding-left: 20px;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .capsule-for-icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      #Layer_1 {
        height: 35px;
      }
    }
  }

  .social-media-mobile {
    width: fit-content;
    .icon {
      color: $darkGray;
      font-size: 20px;
      margin-left: 20px;
      text-overflow:unset;

      &:hover {
        color: $White;
      }
    }
  }

  .title-menu-item {
    @include textFormatting('Poppins-Regular', 12pt, $White, uppercase, pointer);
    &:hover {
      color: $mainOrange;
    }
  }

  .menu-separator {
    height: 0;
    margin: 15px 0;
    border-top: 1px solid $darkGray;
  }
}

