.number-input{
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid $darkGray;
  cursor: pointer;
  justify-content: center;
  background: $White;
}

.minus, .plus{
  @include textFormatting('Roboto-Regular', 10pt, $Black, uppercase, pointer);
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    text-decoration: none;
    color: $darkGray;
  }
}

input.quantity {
  margin:0 5px;
  height: 28px;
  width: 30px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border:0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus{
    outline:none;
  }
}
