.list-of-categories{
  //height:auto;
  max-height:100vh;
  display: none;
  width:100%;
  background-color: $Black;
  z-index: 2;
  position: absolute;
  top:0;
  overflow: scroll;
  .close-icon{
    margin-top:20px;
    margin-left:20px;
  }
  .out-info{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    padding: 0 13%;
      .range-block{
        display: grid;
        align-items: center;
        justify-content: center;
        margin: 20px auto;
      }

      .range-products{
        display: flex;
        align-items: center;
        flex-direction: column;
        &:hover {
          text-decoration: none;
          cursor:pointer;
          .icon-title{
            color: $mainOrange;
          }
        }
        .capsule-for-icon{
          width:50px;
        }
        .icon-title {
          @include textFormatting('Poppins-Bold', 8pt, $White, uppercase, pointer);
          padding-top: 10px;
        }
      }

      .icons-info {
        flex-wrap: wrap;
        display: grid;
        align-items: start;

        .category-of-products{
          width:100%;
          height:120px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-color: transparent;
          &:hover {
            text-decoration: none;
            cursor:pointer;
            .category-title{
              color: $mainOrange;
            }
          }
        }
        .capsule-for-icon{
          align-items: flex-end;
          justify-content: center;
          display: flex;
          width: 40px;
          height: 30px;
        }
        .category-title{
          text-align: center;
        }
      }

      @media only screen and (min-width:1200px) {
        .icons-info {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          .capsule-for-icon svg#Layer_1{
            width: 40px;
            height: 30px;
          }
          .category-title{
            @include textFormatting('Poppins-Bold', 8pt, $White, uppercase, pointer);
            margin-top: 20px;
            height: 40px;
          }
        }
        .range-block{
          width:50%;
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }

      @media only screen and (min-width:768px) and (max-width: 1199.98px) {
        .range-block{
          width:100%;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          padding-bottom:50px;
        }
        .icons-info {
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap: 20px;
          .capsule-for-icon svg#Layer_1{
            width: 50px;
            height: 50px;
          }
          .category-title{
            @include textFormatting('Poppins-Bold', 8pt, $White, uppercase, pointer);
            margin-top: 20px;
            height: 20px;
          }
        }
      }

      @media only screen and (max-width: 767.98px) {
        .range-block{
          width:100%;
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;
          padding-bottom:60px;
        }

        .icons-info {
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;
          .capsule-for-icon svg#Layer_1{
            width: 60px;
            height: 60px;
          }
          .category-title{
            @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
            margin-top: 20px;
            height: 20px;
          }
        }
      }
  }
}
