#news-article {
  .header-page {
    border-bottom: 1px solid $mainOrange;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .title-page {
      text-align: center;
      @include textFormatting('Poppins-Bold', 17pt, $mainOrange, none, pointer);
      &:hover{

      }
    }
  }

  .body-page {
    display: flex;
    min-height: 600px;
    .article-info{
      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: flex-start;
      .article-date{
        margin-top:10px;
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, uppercase, default);
      }
      .article-title{
        @include textFormatting('Poppins-Bold', 17pt, $mainGray, none, default);
        margin:5px 0 0 0;
      }
      .article-description{
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
        margin:10px 0;
      }
      .share-article{
        height:40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom:20px;
        .share-icon{
          width: 20px;
          margin: 0 10px;
          cursor: pointer;
        }

        .option-to-share{
          visibility:hidden;
          display: flex;
          align-items: center;
          padding: 10px 0 0 0;
        }

        .social_share i{
          font-size: 16px;
          color: $mainGray;
          &:hover{
            color: $mainOrange;
          }
        }
      }
    }
  }

  @media only screen and (min-width:768px){
    .article-img, .article-info{
      width:50%;
    }
  }

  @media only screen and (max-width:767.98px){
    .body-page {
      flex-direction: column;
    }
    .article-img, .article-info{
      width:100%;
    }
    .article-img{
      margin:20px 0;
    }
  }

}
