.counter-click{
  border-radius: 5px;
  border: 1px solid $darkGray;
  width: fit-content;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  button{
    background-color: $White;
    border:0;
    @include textFormatting('Roboto-Regular', 10pt, $Black, uppercase, pointer);
    width: 20px;
    &:focus{
      outline:0;
    }
  }
  input{
    width: 30px;
    text-align: center;
    border:0;
    @include textFormatting('Roboto-Regular', 10pt, $Black, uppercase, text);
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:focus{
      outline:0;
    }
  }
}
