#baseboard{
  width:100%;
  border-top: 10px solid $mainGray;
  background-color: $Black;
  bottom:0;
  position:relative;

  .hp-anchor{
    width:200px;
    height:60px;
  }
  .logo-section{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body-section{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: space-between;
  }

  .col-social-media .icons-social-media, .col-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    //width: 25%;
  }

  .anchor-footer,
  .col-social-media .icons-social-media .icon {
    @include textFormatting('Roboto-Regular', 10pt, $darkGray, none, pointer);
    &:hover{
      @include textFormatting('Roboto-Bold', 10pt, $mainOrange, none, pointer);
      text-decoration: none;
    }
  }

  .anchor-footer,
  .col-social-media .icons-social-media .icon,
  .menu-item {
   min-height: 30px;
  }

  .col-categories {
    ul{
      padding: 0;
      list-style: none;
    }
  }

  .footer-section{
    border-top: 1px solid $mainGray;
    display: flex;
    justify-content: center;
    .footer-links-text, .page-terms {
      @include textFormatting('Roboto-Regular', 8pt, $darkGray, uppercase, default);
    }
    .page-terms {
      @include textFormatting('Roboto-Regular', 8pt, $darkGray, uppercase, pointer);
      &:hover {
        color: $mainOrange;
        text-decoration: none;
      }
    }
    .footer-link-powered {
      @include textFormatting('Roboto-Regular', 8pt, $darkGray, none, default);
    }

    .footer-link-powered a{
      @include textFormatting('Roboto-Regular', 8pt, $darkGray, none, pointer);
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .col-menu, .col-categories, .col-social-media {
    width:100%;
  }

  .col-categories{
    margin:20px 0;
  }

  .body-section, .footer-section{
    padding: 0 30px;
  }
  .privacy-block{
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .col-menu, .col-social-media, .col-categories {
    width: 20%;
  }
  .body-section, .footer-section{
    padding: 0;
  }
}

@media only screen and (max-width: 991.98px) {
  .footer-section{
    align-items: flex-start;
    height:85px;
    flex-direction: column;
  }
}

@media only screen and (min-width: 992px) {
  .footer-section{
    align-items: center;
    height:65px;
    .footer-links-text {
      @include textFormatting('Roboto-Regular', 10pt, $darkGray, uppercase, default);
    }
    .page-terms {
      @include textFormatting('Roboto-Regular', 10pt, $darkGray, uppercase, pointer);
      &:hover {
        color: $mainOrange;
        text-decoration: none;
      }
    }
    .footer-link-powered{
      margin-right: 0;
      margin-left: auto;
      display: block;
    }
  }
}

.footer-product-menu{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.30s ease-out;
  padding-bottom: 1em;
  margin-top: 0!important;
  position: relative;

  &.open-footer {
    max-height: 3000px;
    transition: max-height 0.30s ease-in;
    .footer-icon{
      transform: translateX(-50%) rotate(180deg);
    }
  }
  .footer-icon{
    font-weight: 900;
    color: $darkGray;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%)
  }
  .col-categories{
    margin-top: 1em;
  }

}

