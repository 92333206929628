.pub {
  margin: 40px auto;
  .img-pub {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 420px;
    position: relative;
  }
  .filter-pub {
    width: 100%;
    height: inherit;
    mix-blend-mode: multiply;
    opacity: 0.6;
    display: flex;
    align-items: center;
    position: absolute;
  }
  .info-pub {
    width: 240px;
    height: 420px;
    margin: auto 40px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .type-pub {
      @include textFormatting('Poppins-Bold', 14pt, $White, none, default);
    }

    .title-pub {
      margin: 0;
      @include textFormatting('Poppins-Bold', 24pt, $White, none, default);
    }

    .description-pub {
      @include textFormatting('Roboto-Regular', 10pt, $White, none, default);
      margin-top: 35px;
      margin-bottom: 115px;
    }

    .anchor-to-pub {
      span {
        @include textFormatting('Poppins-Bold', 14pt, $White, none, pointer);

        &:before {
          content: '>';
          padding-right: 10px;
        }

        &:hover {
          color: $mainOrange;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .pub {
    padding: 0;
    .filter-pub {
      clip-path: polygon(0% 0%, 93% 0%, 67% 100%, 0% 100%);
    }
  }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  .pub {
    padding: 0;
    .filter-pub {
      clip-path: polygon(0% 0%, 45% 0%, 33% 100%, 0% 100%);
    }
  }
}

@media only screen and (min-width: 992px) {
  .pub .filter-pub {
    clip-path: polygon(0% 0%, 35% 0%, 25% 100%, 0% 100%);
  }
}
