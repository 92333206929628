#payments-accordion{
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .card{
    border: 1px solid #3b3b3b;
    margin-bottom: 10px;
    align-items: center;
    border-radius: 5px;
    &.disabled{
      opacity: 0.5;
      cursor: default;
      *{
        cursor: default !important; // para mais tarde recordar,Sitação da Bartara "ainda te vais lembrar de mim"
      }
    }
  }

  .card, .card-header{
    border-radius: 5px;
    background-color: white;
  }

  .card-header{
    height: 50px;
    width:100%;
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border:0;
    input[name='option-payment']:checked{
      background-color: $mainOrange;
    }
    input[name='option-payment']{
      margin-left: 5px;
      border:2px solid white;
      box-shadow:0 0 0 1px $mainOrange;
      appearance:none;
      border-radius:50%;
      width:12px;
      height:12px;
      background-color:#fff;
      transition:all ease-in 0.2s;
    }

    .mb-logo, .mbway-logo, .cc-logo, .bt-logo {
      height: 100%;
      object-fit: cover;
      cursor:pointer;
      padding-left: 15px;
    }

    .chosen-option{
      height: 40px;
      padding:0;
      border:0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      width: 100%;
      button:focus{
        box-shadow: none;
      }
    }
  }

  .card-body {
    text-align: center;
  }

  .all-info-payments{
    display: flex;
    grid-gap: 20px;
  }

  .info-of-payment, .values-of-payment{
    display: flex;
    flex-direction: column;
  }

}

