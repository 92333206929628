//.product-detail-card{
  .sale-type-block{
    height:30px;
    width: 120px;
    position: absolute;
    margin-left: -30px;
    z-index:1;
    clip-path: polygon(0 0, 100% 0, 71.4% 100%, 0% 100%);
    align-items: center;
    display: flex;
    padding-left: 10px;
    .title-content {
      @include textFormatting('Poppins-Bold', 6pt, $White, uppercase, default);
      margin: 0;
    }
  }

.percentage_cart{
  background-color: #009ad4;
}

.amount_cart{
  background-color: #61b62f;
}

.offert_cart{
  background-color: #ff0000;
}

.news_cart{
  background-color: $orange;
}

.bestseller_cart{
  background-color: #00988c;
}

  @media only screen and (max-width:574.98px) {
    .sale-type-block{
      margin-left: -15px;
    }
  }
//}
