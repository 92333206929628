.products-list{
  display: grid;
}

.product-detail-card{
  position:relative;
  border: 1px solid $darkGray;
  padding: 10px 20px;
  width: 245px;
  .iconography{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.35rem;
    img{
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }
  .icon-content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
  }
  .col-icons{
    display: flex;
    align-items: center;
    .like-in-product, .video-product{
      width:20px;
      cursor:pointer;
    }
    .video-product{
      margin-right:10px;
    }

    div.basket-anchor {
      width: 40px;
      margin-left: 5px;
      // border: 1px solid $darkGray;
      // border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon-basket {
        width: 20px;
      }
    }
  }
}

@media only screen and (max-width:574.98px){
  .products-list{
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin: 15px auto;
  }

  .product-detail-card{
    width: 170px;
    padding: 5px 10px 10px 10px;
  }
}

@media only screen and (min-width:575px) and (max-width:991.98px) {
  .products-list{
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;
    margin:40px auto;
    width:480px;
  }

  .product-detail-card{
    width:220px;
  }
}

@media only screen and (min-width:992px){
  .products-list{
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin:40px auto;
  }
}

@media only screen and (min-width:992px) and (max-width:1199.98px) {
  .products-list{
    width:960px;
  }

  .product-detail-card{
    width:220px;
  }
}

