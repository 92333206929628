.slideshow-product {
  &.full {
    height: 80%;

    .parent_div {
      height: 80vh;
    }

    .image {
      height: 80vh;
      background-position: center;
    }

  }

  margin-bottom: 40px;
  height: 400px;

  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    //padding: 1rem 0;
    list-style-type: none;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);

    li {
      margin: 0 0.25rem;
    }

    button {
      display: block;
      width: 1.5rem;
      height: .25rem;
      padding: 0;
      border: none;
      background-color: rgb(255 119 0 / 50%);
      text-indent: -9999px;
      outline: 0;
    }

    li.slick-active button {
      background-color: rgb(255 119 0 / 100%);
      opacity: 1;
    }

  }

  .image {
    height: 100%;
    min-height: 395px;
    display: flex;
    background-repeat: no-repeat;
    background-position: 50% 100%;
  }

  .youtube-iframe {
    object-fit: cover;
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    border: none;
  }

  .slider-text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 295px;
    position: absolute;
    top: 0;
    width: 350px;

    .title-slider {
      @include textFormatting('Poppins-Bold', 17pt, $White, none, default);
      text-shadow: 0 0.75px $mainGray;
    }

    h2 {
      @include textFormatting('Poppins-Bold', 10pt, $White, none, default);
      margin: 20px 0;
      text-shadow: 0 0.75px $mainGray;
    }

    .type-of-discount {
      padding: 0 10px;
      width: fit-content;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.9;

      h1 {
        @include textFormatting('Poppins-Bold', 17pt, $White, uppercase, default);
        text-shadow: 0 0.75px $mainGray;
        margin: 0;
      }
    }
  }

  .content-price {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 180px;
    width: 180px;
    top: 200px;
    left: 75%;
    border-radius: 50%;

    .old-price {
      margin: 0;
      @include textFormatting('Roboto-Slab-Black', 18pt, $mainGray, none, default);
      text-decoration: line-through;
    }

    .new-price {
      margin: 0;
      @include textFormatting('Roboto-Slab-Black', 27pt, $mainGray, none, default);
    }

    h3 {
      margin: 0;
      @include textFormatting('Roboto-Slab-Black', 6pt, $mainGray, none, default);
      text-align: center;
    }
  }

  @media only screen and (max-width: 767.98px) {
    .slider-text {
      width: 300px;
    }

    .content-price {
      height: 100px;
      width: 100px;
      top: 290px;
      left: 65%;

      .old-price {
        font-size: 14pt
      }

      .new-price {
        font-size: 20pt;
      }

      h3 {
        font-size: 4pt;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    span.next, span.previous {
      width: 20px;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      align-items: center;
      display: flex;
      justify-content: center;
      @include textFormatting('Poppins-Regular', 20pt, $darkGray, uppercase, pointer);
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1399.98px) {
    span.previous {
      left: 10px;
    }
    span.next {
      right: 10px;
    }
  }

  @media only screen and (min-width: 1400px) and (max-width: 1699.98px) {
    span.previous {
      left: 10%;
    }
    span.next {
      right: 10%;
    }
  }

  @media only screen and (min-width: 1700px) {
    span.previous {
      left: 15%;
    }
    span.next {
      right: 15%;
    }
  }

  @media only screen and (max-width: 991.98px) {
    span.next, span.previous {
      display: none !important;
    }
  }

  @media only screen and (min-width: 1920px) {
    .image {
      background-size: 100%;
    }
  }

  .parent_div {
    position: relative;
    height: 400px;

    video {
      object-fit: cover;
      margin: auto;
      display: block;
      width: 100%;
      height: 100%;

      &::cue {
        color: $white;
        background: rgba(0, 0, 0, 0.5);
        font: 17pt "Poppins-Regular", sans-serif;
        text-shadow: 0 0.75px $mainGray;
      }
    }

    .put-sound {
      position: absolute;
      top: 20px;
      right: 40px;
      color: #FF7700;
      cursor: pointer;
      z-index: 120000;
      font-size: 25px;
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .slideshow-slick {
    .isDesktop {
      display: none;
    }

    .isMobile {
      display: block;
    }
  }
}

@media only screen and (min-width: 768px) {
  .slideshow-slick {
    .isDesktop {
      display: block;
    }

    .isMobile {
      display: none;
    }
  }
}
