
.search-box{
  display: flex;
  align-items: center;
  height: 60px;

  width:100%;
}
.search-content{
  width: 100%;
  .form-group{
    margin:0;
    display:flex;
    justify-content: flex-end;
  }
  .search-input{
    background-color: $mainGray;
    border-radius: 0px;
    border:none;
    border-bottom: 1px solid $darkGray;
    color: $darkGray;
    font-size:10pt;
    padding: 0;
    max-width: 400px;
    text-align: left;
    &:focus{
      border-bottom-color: $White;
      box-shadow: none;
    }
  }
  .btn-search{
    width: 40px;
    height: 40px;
    border:none;
    border-radius:0;
    border-color:unset;
    &:focus{
      box-shadow: none;
    }
  }
}

@media only screen and (min-width:768px) {
  .search-box{ margin:0 20px; }
}

@media only screen and (max-width:767.98px) {
  .search-box{ margin-left: 60px; }
}

