#slideshow-fullscreen {
  top: 0;
  left: 0;
  position: fixed;
  z-index:1000;
  background-color: rgb(59 59 59 / 85%);
  width: 100%;
  height: 100%;
  display:none;
  .count-images {
    height: 60px;
    width: 100%;
    background-color: $mainGray;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .close-icon{ margin-left:20px; }
    .number-image{
      @include textFormatting('Roboto-Regular', 12pt, $White, uppercase, default);
      margin:auto;
    }
  }

  #slideshow-imgs{
    display: flex;
    align-items: center;
    justify-content: center;
    figure{
      justify-content: center;
      align-items: center;
      display: flex;
      height: calc(100vh - 60px);
      width: 100%;
      img{
        height:85vh;
      }
    }
    video, iframe {
      justify-content: center;
      align-items: center;
      display: flex;
      height: calc(100vh - 80px);
      width: 100%;
    }
  }

  .previous-img, .next-img{
    @include textFormatting('Poppins-Regular', 30pt, $White, uppercase, pointer);
    z-index: 1001;
    position: absolute;
    top: 50%;
  }

  .previous-img { left: 20px; }

  .next-img{ right: 20px; }
}


@media only screen and (max-width: 767.98px){
  #slideshow-fullscreen #slideshow-imgs figure img{
    height: 45vh;
  }
}
