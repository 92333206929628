// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.4;

// Colors
/*$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;*/

$mainGray : #3b3b3b;
$mainOrange : #FF7700; //
//$teste:  rgb(213, 122, 35);
$darkGray: #9a9b9e; //#999a9c
$lightGray: #E4E4E4;
$White: #fff;
$Black: #191815;

$Green: #327b54;
$Red: #e3342f;

$bgLightGray: #F7F7F7;
