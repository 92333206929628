#favorites-list , #my-bis{
  .title-page{
    @include textFormatting('Poppins-Bold', 13pt, $mainOrange, none, default);
  }

  @media only screen and (min-width:1200px) {
    .product-grid{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap:20px;
    }
  }

  @media only screen and (max-width:1199.98px) and (min-width:992px) {
    .product-grid{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap:10px;
    }
  }

  @media only screen and (max-width:991.98px) and (min-width:768px) {
    .product-grid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap:20px;
    }
  }

  @media only screen and (max-width:767.98px){
    .title-page{
      margin-top: 20px;
    }

    .product-grid{
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;
      justify-content: space-around;
    }
  }
}
