#about-us {
  .featured-image-bg {
    width:100%;
    height:290px;
    video{
      height: 300px;
      align-items: center;
      justify-content: center;
      display: block;
      margin: auto;
      width: 100%;
      object-fit: cover;
    }
  }

  .title-article {
    margin:20px 0;
    @include textFormatting('Poppins-Bold', 17pt, $mainOrange, none, default);
  }

  .description-article {
    margin:20px 0;
    @include textFormatting('Poppins-Regular', 12pt, $mainGray, none, default);
    p { margin:0;}
    strong {
      @include textFormatting('Poppins-Bold', 12pt, $mainOrange, none, default);
    }
  }

  .separator-line {
    border-top:1px solid $mainOrange;
    margin:0;
  }

  figure{
    width:100%;
    margin:0;
  }

  .each-image{
    width: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width:767.98px){
    .featured-image-bg {
      background-position: 57% 40%;
    }

    .each-image{
      height: 350px;
    }
  }

  @media only screen and (max-width:991.98px) and (min-width:768px){
    .featured-image-bg {
      background-position: 60% 40%;
    }
    .each-image{
      height: 350px;
    }
  }

  @media only screen and (min-width:992px) {
    .featured-image-bg {
      background-position: 0% 40%;
    }

    .image-gallery{
      display: flex;
      width:100%;
      height:335px;
    }

    .each-image{
      height: 335px;
    }
  }
}
