#shipping-addresses{
  .each-address{
    border:1px solid $lightGray;
    padding: 10px;
  }

  .each-info-address{
    display: flex;
    flex-direction: column;
  }

  .user-address{
    @include textFormatting('Poppins-Bold', 10pt, $mainGray, none, default);
  }
  .info-address{
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
  }

  .div-to-change{
    display: flex;
    justify-content: space-between;
    margin-top:10px;
  }

  .delete-address, .edit-address, .add-new-address{
    width: fit-content;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:0;
    &:focus{
      outline: 0;
    }
  }

  .edit-address{
    background-color: $darkGray;
    @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
    &:hover{
      box-shadow: 0 0 0 0.2rem rgb(154 155 158 / 25%);
      border-color: $lightGray;
      color: $mainGray;
    }
  }

  .delete-address, .add-new-address{
    background-color: $mainOrange;
    @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
    &:hover{
      box-shadow: 0 0 0 0.2rem rgb(154 155 158 / 25%);
      border-color: $darkGray;
      color: $mainGray;
    }
  }

  .add-new-address{
    margin-top:20px;
  }

  #modalOfUserAddressForm{
    #titleOfUserAddressFormModal{
      @include textFormatting('Poppins-Bold', 13pt, $mainGray, uppercase, default);
    }
    form{
      .form-group, .form-row{
        margin-bottom:0.75rem;
      }

      .form-label{
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
        margin-bottom: 3px;
      }

      .form-control{
        height: 40px;
        margin:0;
        text-align: left;
        &:focus{
          box-shadow: 0 0 0 0.2rem rgb(213 122 35 / 25%);
          border-color: $mainOrange;
        }
      }

      .form-required:after{
        content: '*';
        color: $mainOrange;
        padding-left: 5px;
      }

      .form-check {
        margin: 10px 0;
        align-items: center;
        display: flex;
        width: 100%;

        #address_type_billing, #address_type_delivery {
          width: 15px;
          height: 15px;
          margin-top: 0;

          &:checked {
            accent-color: $mainOrange;
          }
        }

        .form-check-label {
          margin-bottom: 0;
          @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
        }

        a {
          @include textFormatting('Poppins-Bold', 10pt, $mainGray, none, pointer);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

  }


  @media only screen and (min-width:992px) {
    .all-addresses{
      display:grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap:20px;
    }
  }

  @media only screen and (max-width:991.98px) and (min-width:575px) {
    .all-addresses{
      display:grid;
      grid-template-columns: 1fr 1fr;
      grid-gap:20px;
    }
  }

  @media only screen and (max-width:574.98px){
    .all-addresses{
      display:flex;
      flex-direction: column;
      grid-gap:20px;
      margin-top:20px;
    }
  }


}
