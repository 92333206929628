.product-detail-card {
  .product-info-block {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .anchor-product-title{
      height: 45px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .anchor-product-title:hover {
      text-decoration: none;
    }

    .product-title {
      @include textFormatting('Roboto-Bold', 10.5pt, $Black, uppercase, pointer);
      text-align: center;
    }

    .product-excerpt {
      height: 40px;
      @include textFormatting('Poppins-Regular', 8pt, $Black, uppercase, default);
      text-align: center;
    }

    .no-excerpt {
      height: 40px;
    }

    .product-category {
      @include textFormatting('Roboto-Regular', 10pt, $darkGray, uppercase, pointer);
      text-align: center;
    }

    .anchor-for-product {
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }

    .product-price {
      @include textFormatting('Roboto-Slab-Black', 15pt, $mainOrange, uppercase, pointer);
    }

    .product-new-price {
      @include textFormatting('Roboto-Slab-Black', 15pt, $mainOrange, uppercase, pointer);
    }

    .product-old-price {
      @include textFormatting('Roboto-Slab-Black', 15pt, $darkGray, uppercase, pointer);
      text-decoration: line-through;
      padding-right: 7px;
    }

    .all-prices {
      display: flex;
    }



    @media only screen and (max-width: 574.98px) {
      .all-prices {
        flex-direction: column;
        align-items: center;
      }
      .product-price, .product-new-price, .product-old-price {
        font-size: 13pt;
        margin-bottom: 0;
      }
      .product-old-price {
        padding-right: 0;
      }
    }
  }

}

@media only screen and (max-width: 379.98px) {
  .product-detail-card {
    width: 165px;
    padding: 5px;
  }
}
