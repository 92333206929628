.info-tab{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-detail{
  border-block: 1px solid $lightGray;
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  flex-direction: column;
}

.data-name{
  @include textFormatting('Poppins-Bold', 13pt, $mainOrange, none, default);
}

.data-info{
  @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
}
