@font-face {
  font-family: 'Roboto-Regular';
  src: url("fonts/roboto/Roboto-Regular.eot");
  src: url("fonts/roboto/Roboto-Regular.eot") format("embedded-opentype"),
  url("fonts/roboto/Roboto-Regular.woff") format("woff"),
  url("fonts/roboto/Roboto-Regular.ttf") format("truetype"),
  url("fonts/roboto/Roboto-Regular.svg") format("svg");
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url("fonts/roboto/Roboto-Bold.eot");
  src: url("fonts/roboto/Roboto-Bold.eot") format("embedded-opentype"),
  url("fonts/roboto/Roboto-Bold.woff") format("woff"),
  url("fonts/roboto/Roboto-Bold.ttf") format("truetype"),
  url("fonts/roboto/Roboto-Bold.svg") format("svg");
}

@font-face {
  font-family: 'Roboto-Slab-Black';
  src: url("fonts/roboto/RobotoSlab-Black.ttf") format("truetype");
}

@font-face {
  font-family: 'RobotoCondensed-Light';
  src: url("fonts/roboto/RobotoCondensed-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url("fonts/poppins/Poppins-Regular.eot");
  src: url("fonts/poppins/Poppins-Regular.eot") format("embedded-opentype"),
  url("fonts/poppins/Poppins-Regular.woff") format("woff"),
  url("fonts/poppins/Poppins-Regular.ttf") format("truetype"),
  url("fonts/poppins/Poppins-Regular.svg") format("svg");
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url("fonts/poppins/Poppins-Bold.eot");
  src: url("fonts/poppins/Poppins-Bold.eot") format("embedded-opentype"),
  url("fonts/poppins/Poppins-Bold.woff") format("woff"),
  url("fonts/poppins/Poppins-Bold.ttf") format("truetype"),
  url("fonts/poppins/Poppins-Bold.svg") format("svg");
}

@font-face {
  font-family: 'Poppins-Black';
  src: url("fonts/poppins/Poppins-Black.eot");
  src: url("fonts/poppins/Poppins-Black.eot") format("embedded-opentype"),
  url("fonts/poppins/Poppins-Black.woff") format("woff"),
  url("fonts/poppins/Poppins-Black.ttf") format("truetype"),
  url("fonts/poppins/Poppins-Black.svg") format("svg");
}


#breadcrumbs {
    background-color: #e9ecef;
}

.alert.header-message {
    border-radius: 0 !important;
}

.carousel-item-background{
  background-position: center;
  width: 100vh;
  height:100vw ;
  background-size: cover;
}

@mixin textFormatting($font_text, $font_size, $font_color, $text_transform, $cursor){
  font-family: $font_text;
  font-size: $font_size;
  color: $font_color;
  text-transform: $text_transform;
  cursor: $cursor;
}
