#store-list {
  .featured-img-article {
    display: block;
    height: 300px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 1.5rem;
  }

  .agents-type {
    display: flex;
    align-items: center;
  }

  .agent-info {
    display: flex;

    &:first-of-type {
      margin-right: 2rem;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 0.5rem;
    }
  }

  .agent-icon {
    width: 60px;
    height: 60px;
  }

  .store-owner {
    @include textFormatting('Poppins-Bold', 8pt, $mainGray, none, default);
    line-height: 5px;
  }

  .agent-name {
    @include textFormatting('Poppins-Bold', 14pt, $mainGray, none, default);
  }

  .description-article {
    @include textFormatting('Poppins-Regular', 14pt, $mainGray, none, default);
    margin-bottom: 40px;

    p {
      margin: 0;
    }
  }

  .list-of-contacts {
    padding: 40px 0;
    display: grid;
    align-items: start;
    grid-gap: 20px;
    border-top: 1px solid $mainOrange;
  }

  .each-contact {
    margin-bottom: 30px;
  }

  .contact-title {
    @include textFormatting('Poppins-Bold', 20pt, $mainOrange, uppercase, default);
    margin-bottom: 20px;
  }

  .contact-info {
    @include textFormatting('Poppins-Regular', 14pt, $mainGray, none, default);
    margin: 0;
    width: fit-content;
    display: flex;

    &:nth-child(2n) {
      margin-bottom: 20px;
    }
  }

  a.contact-info {
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: $mainOrange;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .list-of-contacts {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (max-width: 767.98px) {
    .list-of-contacts {
      grid-template-columns: 1fr;
    }
    .agents-type {
      flex-direction: column;
      align-items: flex-start;

      .agent-info:first-of-type {
        margin-bottom: 2rem;
        margin-right: 0;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .list-of-contacts {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .location-search {
    margin-bottom: 20px;

    #form_aux_map {
      display: flex;
    }

    #location-search-input {
      @include textFormatting('Poppins-Regular', 12pt, $mainGray, none, text);
      background-color: $lightGray;
      border-radius: 5px;
      padding: 6px 10px;
      max-width: 400px;
      width: 100%;
      height: 40px;
      text-align: left;
      border: 0;
      margin-right: 0.25rem;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgb(212 120 33 / 25%);
        border-color: $mainOrange;
        outline: 0;
      }
    }


    #location-search-submit {
      @include textFormatting('Poppins-Regular', 12pt, $White, none, pointer);
      background-color: $mainOrange;
      border-radius: 5px;
      width: fit-content;
      padding: 10px 15px;
      text-align: center;
      height: 40px;
    }
  }

  #hidden-agents-to-use{
    display: none;
  }

}

#gMap {
  width: 100%;
  height: 600px;
}

.ui-autocomplete {
  background: $lightGray;
  border: 1px solid $darkGray;
  border-radius: 5px;
  @include textFormatting('Poppins-Bold', 8pt, $mainGray, none, pointer);
  margin: 0 0 3px 0;
  padding: 5px 10px;
  position: absolute;
}

.ui-autocomplete .ui-menu-item {
  list-style: none outside none;
  padding: 7px 0;
}

.ui-autocomplete .ui-corner-all {
  color: #666 !important;
  display: block;
}

.agents-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 20px;

  .agents-item {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid $darkGray;
  }

  .location-info, .agentInfo {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .location-info {
    .distance-info {
      margin-bottom: 10px;
    }

    .distance-info, .get-directions-info {
      display: flex;
      align-items: center;
    }

    .distance {
      @include textFormatting('Poppins-Bold', 12pt, $mainGray, none, default);
    }

    .distance-agent {
      @include textFormatting('Poppins-Regular', 12pt, $mainGray, none, default);
    }

    .get-directions-info, .how-to-get {
      @include textFormatting('Poppins-Bold', 12pt, $mainGray, none, pointer);

      &:hover {
        text-decoration: none;
        color: $mainOrange;
      }
    }

    .icon-distance {
      margin: 0 10px 0 20px;
    }
  }
}


#form_aux_map_route {
  display: flex;
  flex-direction: column;

  label {
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, text);
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;

    sub {
      bottom: 0;
      @include textFormatting('Poppins-Regular', 8pt, $mainGray, none, text);
    }
  }

  #locationSearchPostalCode, #locationSearchLocality {
    padding: 6px 25px;
    background-color: $lightGray;
    text-align: left;
    @include textFormatting('Poppins-Regular', 12pt, $mainGray, none, text);
  }

  #route_submit {
    background-color: $mainOrange;
    padding: 10px 15px;
    text-align: center;
    @include textFormatting('Poppins-Regular', 12pt, $White, none, pointer);
  }

  #locationSearchPostalCode, #locationSearchLocality, #route_submit {
    height: 40px;
    width: 300px;
    border-radius: 5px;
    margin: 0 0 20px 0;
  }
}

#gMapAgent {
  height: 600px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.agent-detail {
  display: none;
}

#agent-detail-info .agentInfo {
  display: flex;
  flex-direction: column;
}

#agent-detail-info, .agents-item {
  .store-type{
    @include textFormatting('Poppins-Bold', 8pt, $mainOrange, uppercase, default);
  }

  .store-name {
    @include textFormatting('Poppins-Bold', 12pt, $mainGray, none, default);
    margin-bottom: 10px;
  }

  .store-address {
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
    margin-bottom: 10px;
  }

  .store-telephone, .store-mobilephone, .store-email, .store-website {
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, pointer);

    &:hover {
      color: $mainOrange;
      text-decoration: none;
    }
  }

  .store-email {
    margin: 10px 0;
  }
}

.header-second-part {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
  border-bottom: 1px solid $darkGray;
  padding-bottom: 5px;

  #agentRouteTitle {
    @include textFormatting('Poppins-Bold', 12pt, $mainGray, none, default);
  }
}

.option-to-user {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .btn-back, .btn-new-search {
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $mainOrange;
    border-radius: 5px;
    margin: 10px 0 5px 0;
    @include textFormatting('Poppins-Regular', 12pt, $White, none, pointer);
  }
}

.ui-helper-hidden-accessible{
  display: none!important;
}
