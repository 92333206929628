#account-page{
  padding:40px 0;
  min-height: calc(100vh - 405px);
  .menu-account{
    height:200px;
    margin-right: 40px;
    border-top: 1px solid $lightGray;
    li{
      width:100%;
      border-bottom: 1px solid $lightGray;
      border-radius: 0;
      align-items: center;
      display: flex;
      justify-content: left;
      a{
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, pointer);
      }
      a.active{
        @include textFormatting('Poppins-Bold', 10pt, $mainOrange, none, pointer);
      }
    }
  }

  @media only screen and (min-width:768px){
    .menu-account{
      width:200px;
    }
    .info-tabs-account{
      width:calc(100% - 240px);
    }
  }

  @media only screen and (max-width:767.98px){
    .container .row{
      flex-direction: column;
    }

    .menu-account, .info-tabs-account{
      width:90%;
      margin:0 auto
    }
  }
}
