.technical-details-navbar{
  .nav-tabs{
    border-bottom: 1px solid $mainOrange;
  }
  .nav-link{
    width: 33.3%;
    height: 40px;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    @include textFormatting('Roboto-Regular', 12pt, $mainGray, uppercase, pointer);
  }

  .nav-link.active{
    background-color:$lightGray;
    border-color:$lightGray;
  }

  .nav-link:hover{
    text-decoration: underline;
  }
}

.no-info{
  @include textFormatting('Roboto-Regular', 12pt, $mainGray, uppercase, default);
}

#nav-tabContent{
  min-height:300px;
  margin-top:20px;
}

.product-table{
  display: block;
  overflow-x: auto;
  white-space: nowrap;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $darkGray;
      border-radius: 20px;
      border: 1px solid transparent;
    }

  tbody tr:nth-of-type(odd){
    background-color: $lightGray;
  }

  tr > th , tr > td {
    padding: 5px;
    @include textFormatting('Roboto-Regular', 12pt, $mainGray, capitalize, default);
  }
}

.info-product-tab{
  @include textFormatting('Roboto-Regular', 12pt, $mainGray, none, default);
}

.dynamic-documents-list{
  margin-top:40px;
  label{
    @include textFormatting('Roboto-Bold', 12pt, $mainGray, none, default);
  }
  .dynamic-documents-list-files{
    list-style: none;
    padding:0;
    li a {
      @include textFormatting('Roboto-Regular', 12pt, $mainGray, none, pointer);
      &:hover{ text-decoration: none; }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .technical-details-navbar .nav-link, .product-table tr > td {
    font-size:10pt;
  }
}
