#multiple-products {
  width: 100%;
  display: flex;
  align-items: center;
  height: 400px;

  .slick-arrow {
    font-size: 20px;
    cursor: pointer;
    padding: 0 20px;
  }

  .slick-list {
    margin: 0 -10px;
  }

  .slick-slide {
    margin: 0 10px;
  }
}

.relatedProductsWithoutSlide {
  display: grid;
  grid-gap: 20px;
  width: fit-content;
  margin: 0 auto 40px auto;
}

@media only screen and (min-width:992px) {
  .relatedProductsWithoutSlide {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width:991.98px){
  .relatedProductsWithoutSlide {
    grid-template-columns: 1fr 1fr;
  }
}
