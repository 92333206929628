#categories-section{
  background-color: $Black;
  width:100%;
  .range-block{
    display: flex;
    padding: 50px 0;
    margin: auto;
    flex-direction: row;
  }

  .range-products{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    &:hover {
      text-decoration: none;
      cursor:pointer;
      .icon-title{
        color: $mainOrange;
      }
    }
    .capsule-for-icon{
      width:60px;
    }
    .icon-title {
      @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
      padding-top: 5px;
      text-align: center;
    }
  }

  .icons-info {
    padding-top: 20px;
    border-bottom: 1px solid $darkGray;
    flex-wrap: wrap;
    display: grid;
    align-items: start;
    .category-of-products{
      width:100%;
      height:120px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: transparent;
      &:hover {
        text-decoration: none;
        cursor:pointer;
        .category-title{
          color: $mainOrange;
        }
      }
    }
    .capsule-for-icon{
      align-items: flex-end;
      justify-content: center;
      display: flex;
      height:60px;
    }
    .category-title{
      text-align: center;
    }
  }

  @media only screen and (min-width:992px) {
    .range-block{
      width:90%;
     // grid-template-columns: 1fr 1fr 1fr 1fr;
     // grid-gap: 40px;
     // padding-bottom:80px;
    }
    .icons-info {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 40px;
      max-height: 180px;
      padding-bottom: 40px;
      overflow: hidden;
      transition: max-height 1s ease-out;
      position: relative;
      &.open{
        max-height: 3000px;
        transition: max-height 1s ease-in;
      }

    .categories-arrow{
      font-size: 20px;
      position: absolute;
      color: white;
      left: 50%;
      transform: translateX(-50%);
      bottom: 13px;
      &.open{
        transform: translateX(-50%) rotate(180deg);

      }
    }
      .capsule-for-icon svg#Layer_1{
        width: 60px;
        height: 60px;
      }
      .category-title{
        @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
        margin-top: 20px;
        height: 40px;
      }
    }
  }

  @media only screen and (min-width:768px) and (max-width: 991.98px) {
    .range-block{
      width:100%;
      grid-template-columns: 1fr 1fr 1fr 1fr;
   //   padding-bottom:60px;
    }
    .icons-info {
      position: relative;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 20px;
      overflow: hidden;
      transition: max-height 1s ease-out;
      max-height: 180px;
      &.open{
        max-height: 3000px;
        transition: max-height 1s ease-in;
      }
      .category-of-products{
        height:140px;
      }
      .capsule-for-icon svg#Layer_1{
        width: 60px;
        height: 60px;
      }

      .categories-arrow{
        font-weight: 900;
        font-size: 40px;
        position: absolute;
        color: white;
        left: 50%;
        transform: translateX(-50%);
        bottom: 6px;
        &.open{
          transform: translateX(-50%) rotate(180deg);

        }
      }
      .category-title{
        @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
        margin-top: 20px;
        height: 70px;
      }
    }
  }

  @media only screen and (max-width: 767.98px) {
    .range-block{
      width:100%;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      display: grid;
     // padding-bottom:60px;
    }

    .icons-info {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      padding-bottom: 20px;
      .categories-arrow{
        display: none;
      }
      .capsule-for-icon svg#Layer_1{
        width: 60px;
        height: 60px;
      }
      .category-title{
        @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
        margin-top: 20px;
        height: 20px;
      }
    }
  }
}
