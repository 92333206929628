.search{
  min-height: calc(100vh - 446px);
  width:80%;
  display:block;
  align-items:center;
  margin: 0 auto;
  .section-identification{
    text-align: center;
    margin: 40px auto;
    border-bottom: 1px solid $darkGray;
    .search-section-title{
      @include textFormatting('Poppins-Bold', 17pt, $mainGray, uppercase, default);
    }
  }
  .section-content{
    margin-bottom:20px;
    display: block;
    .search-result-title{
      display:block;
      @include textFormatting('Roboto-Regular', 12pt, black, none, default);
      margin-bottom:40px;
    }
    .card{
      margin-bottom:20px;
      border: none;
      .img-fluid{ cursor:pointer; }
      .card-title{
        @include textFormatting('Poppins-Bold', 14pt, black, capitalize, default);
        cursor:pointer;
        margin:0;
      }
      .card-cat-text{
        @include textFormatting('Roboto-Regular', 8pt, $darkGray, lowercase, default);
        margin:0;
        line-height:7pt;
      }
      .card-text{
        @include textFormatting('Roboto-Regular', 10pt, $darkGray, lowercase, default);
        margin:0;
      }
    }
    a:hover{text-decoration-color: black; cursor:pointer;}
    .search-result-count{
      display:block;
      @include textFormatting('Roboto-Regular', 10pt, black, none, default);
      margin-bottom:40px;
    }
  }
    .page-link{ @include textFormatting('Roboto-Regular', 12pt, $mainGray, uppercase, default); }

  @media only screen and (max-width: 767.98px) {
    .section-content .card {
      .card-title {
        font-size: 14pt;
      }
      .card-block{
        padding-right: 0rem !important;
      }
    }
  }
}
