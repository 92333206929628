#catalogo-download{
  //height: 400px;
  display: flex;
  align-items: center;

  justify-content: center;

  .info-download{
    display: flex;
    flex-direction: column;
  }

  .title-download{
    @include textFormatting('Poppins-Bold', 25pt, $mainOrange, uppercase, default);
  }
  .description-download{
    @include textFormatting('Poppins-Regular', 16pt, $mainGray, none, default);
    margin:20px 0;
  }
  .anchor-download{
    width:240px;
    height:60px;
    background-color: $mainOrange;
    border-radius:5px;
    @include textFormatting('Poppins-Bold', 20pt, $White, uppercase, pointer);
    align-items: center;
    justify-content: center;
    display: flex;
  }
}


@media only screen and (min-width:992px) and (max-width:1199.98px){
  #catalogo-download{
    margin:40px auto;
    width: 85%;
  }
}

@media only screen and (min-width:1200px) {
  #catalogo-download{
    margin:40px auto;
    width: 70%;
  }
}

@media only screen and (max-width:991.98px) {
  #catalogo-download{
    flex-direction: column;
    img{
      max-width: 100%;
    }
    .info-download{
      margin:20px auto;
      align-items: center;
      .title-download, .description-download,  .anchor-download{
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width:768px) and (max-width:991.98px){
  #catalogo-download{
    margin:40px auto;
    width: 70%;
  }
}


@media only screen and (max-width:767.98px){
  #catalogo-download{
    margin:40px;
  }
}
