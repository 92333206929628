.section-identification-body{
  min-width: 200px;
  padding: 0 0.5rem;
  width: fit-content;
  min-height:40px;
  background-color: $mainOrange;
  display: block;
  margin:auto;
  .title-section {
    @include textFormatting('Poppins-Black', 16pt, $White, uppercase, default);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height:40px;
  }
}

@media only screen and (max-width:539.98px) {
  .section-identification-body .title-section { font-size: 16pt;}
}
