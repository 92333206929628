.lastest-news {
  padding: 20px 0;
  background-color: $bgLightGray;
  .title-section {
    text-align: center;
    @include textFormatting('Poppins-Bold', 17pt, $mainOrange, uppercase, pointer);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .all-articles {
    align-items: flex-start;
  }

  .each-article {
    padding-right: 0;
    padding-left: 0;
  }

  .article-anchor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  img {
    clip-path: polygon(12.7% 0, 100% 0%, 87.3% 100%, 0% 100%);
    width: 100%;
    max-width: 400px;
    height: 200px;
    object-fit: none;
    margin-bottom: 1rem;
  }

  .info-article{
    padding: 0 12%;
    text-align: center;
  }

  .article-date {
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, uppercase, pointer);
  }

  .article-title {
    @include textFormatting('Poppins-Bold', 15pt, $mainOrange, none, pointer);
  }

  .article-excerpt {
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, pointer);
    margin: 10px 0;
  }

  .article-read-more {
    @include textFormatting('Poppins-Bold', 10pt, $mainOrange, uppercase, pointer);
  }

  .go-to-news {
    width: fit-content;
    height: 40px;
    padding: 0.75rem 1.25rem;
    background-color: $mainOrange;
    @include textFormatting('Poppins-Bold', 10pt, $white, uppercase, pointer);
    text-decoration: none;
    margin: 20px auto;
    display: block;
  }
}

