#horizontal-navbar,
.navbar-desktop,
.navbar-mobile {
  width: 100%;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  flex-wrap: wrap;
  padding: 0;
  justify-content: unset;
}

#horizontal-navbar {
  z-index:10;
  @media only screen and (max-width: 767.98px) {
    .navbar-desktop {
      display: none;
    }
    .navbar-mobile {
      display: flex;
      border-bottom:1px solid $darkGray;
      .first_mobile_block{
        width: 60px;
        height:inherit;
        background-color: $mainGray;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom:1px solid $darkGray;

      }
      .second_mobile_block{
        width: calc(100% - 180px);
        height:inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $mainGray;
        border-bottom:1px solid $darkGray;
        .hp-anchor{
          display: contents;
          #logoDormak{ height: 60px; }
        }
      }

      .third_mobile_block{
        width: 120px;
        height:inherit;
        background-color: $mainGray;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom:1px solid $darkGray;

        //.shopping-bag{ width:19px; margin-right: 1rem;}

        #search-icon{
          width:20px;
          display:block;
        }
        .close-icon{display: none;}
      }


      .fourth_mobile_block {
        width:100%;
        height:inherit;
        background-color: $mainGray;
        display: none;
        .search-box {
          width:100%;
          margin-right:60px;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1199.98px){
    .navbar-desktop {
      .third_desktop_block {
        width: calc(100% - 260px); //width: calc(100% - 460px);
        display: flex;
        .menu-item-horizontal{
          display: none;
        }
        span.list-item-horizontal {
          padding: 0 15px;
          a {
            @include textFormatting('Roboto-Bold', 10pt, $darkGray, uppercase, pointer);

            &:hover {
              color: $mainOrange;
              text-decoration: none;
            }
          }
        }
        .open-product-menu{
          padding:0 15px;
          @include textFormatting('Roboto-Bold', 10pt, $darkGray, uppercase, pointer);
          &:hover{
            color: $mainOrange;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1200px){
    .navbar-desktop {
      .third_desktop_block {
        width: calc(100% - 260px);  // width: calc(100% - 460px);
        display: flex;
        align-items: center;

        .menu-item-horizontal{
          height: inherit;
          margin: auto 10px;
          display: flex;
          align-items: center;
          width: 100%;
        }
       span.list-item-horizontal{
         padding:0 15px;
        a {
          @include textFormatting('Roboto-Bold', 10pt, $darkGray, uppercase, pointer);

          &:hover{
            color: $mainOrange;
            text-decoration: none;
          }
        }
       }
        .open-product-menu{
          padding:0 15px;
          @include textFormatting('Roboto-Bold', 10pt, $darkGray, uppercase, pointer);
          line-height: 1.2;
          &:hover{
            color: $mainOrange;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px){
    .navbar-desktop {
      display: flex;
      .first_desktop_block{
        width: 60px;
        height:inherit;
        background-color: $mainGray;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .second_desktop_block{
        width: 200px;
        height:inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $mainOrange;
        .hp-anchor{
          display: flex;
          svg{
            width: 100%;
          }
        }
      }
      .third_desktop_block {
        height:inherit;
        background-color: $mainGray;
      }
      /*
      .fourth_desktop_block {
        display: flex;
        width: 200px;
        height: inherit;
        background-color: $mainGray;
        justify-content: flex-end;

        .icons-actions-menu{
          margin-right: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .icon {
            cursor:pointer;
            &:nth-child(2n -1){
              margin:0 20px;
            }
          }
          #account-icon-page{ width:16px; }
          #wishlist-icon-page{ width:21px; }
          #shopingbag-icon-page{ width:19px; }

          .counter-shopphing-bag{
            border-radius: 50%;
            background-color: $mainOrange;
            width:15px;
            height:15px;
            right: 15px;
            top: 15px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            .number{
              @include textFormatting('RobotoCondensed-Light', 8pt, $White, capitalize, default);
            }
          }
        }
      }
      */
    }
   .navbar-mobile {
      display: none;
     @import "categories-products.scss";
    }
  }
}
