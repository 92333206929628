.products-promotion-slick-slider{
  padding-bottom:40px;
  .slider-img{
    display: flex!important;
    flex-direction: row;
    align-content: center;
  }

  .clip-path-product{
    clip-path: polygon(12.7% 0, 100% 0%, 87.3% 100%, 0% 100%);
    background-repeat: no-repeat;
    height: 250px;
    background-position: center;
    background-size: cover;
  }

  .left-side, .right-side{
    flex: 0 0 50%;
    max-width: 50%;
  }

  .left-side{
    position: relative;
    z-index: 3;
  }

  .right-side{
    display: flex;
    flex-direction: row;
    align-content: center;
    position: relative;
    z-index:4;
  }

  .slick-arrow {
    position: absolute;
    top:calc(50% - 40px);
    z-index: 5;
    @include textFormatting('Poppins-Regular', 12pt, $darkGray, uppercase, pointer);
    padding: 0 20px;
  }

  .slick-prev{
    left:0;
  }

  .slick-next{
    right: 0;
  }

  .product-info{
    text-align: left;
    .product-buy-block{
      justify-content: left;
      margin-bottom:10px;
    }
    div.basket-anchor{
      background-color: $White;
    }
    div.div-like-icon{
      background-color: $White;
      width: 40px;
      margin-left: 10px;
      border: 1px solid $darkGray;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span.like-in-product{
        width:20px;
      }
    }
  }

  .product-img img{
    width:300px;
  }

  .product-category{
    @include textFormatting('Poppins-Regular', 10pt, $darkGray, uppercase, default);
  }

  .product-name{
    @include textFormatting('Poppins-Bold', 10pt, $mainGray, uppercase, pointer);
  }

  .product-line {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid $mainOrange;
  }

  .product-lead{
    @include textFormatting('Poppins-Regular', 8pt, $mainGray, none, default);
  }

  .product-prices {
    display: flex;
    flex-direction: row;
    margin-top:10px;
  }

  .product-new-price{
    @include textFormatting('Poppins-Bold', 20pt, $mainOrange, uppercase, default);
  }
  
  .product-old-price{
    @include textFormatting('Poppins-Bold', 20pt, $darkGray, uppercase, default);
    text-decoration: line-through;
    margin-right: 15px;
  }

  .click-know-more {
    @include textFormatting('Poppins-Bold', 8pt, $mainOrange, uppercase, pointer);
  }

  .iconography{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 0.75rem;
    img{
      width: 25px;
      height: 25px;
      object-fit: cover;
    }
  }
}
#slideshow-1{
  .left-side{
    left: 60px;
  }
  .right-side{
    right: 60px;
  }
}

#slideshow-2{
  .left-side{
    left: -60px;
  }
  .right-side{
    right: -80px;
  }
}

@media only screen and (min-width:992px) and (max-width: 1199.98px) {
  #slideshow-1{
    .right-side{
      right: 120px;
    }
  }

  #slideshow-2 {
    .right-side{
      right: -120px;
    }
  }
}

@media only screen and (min-width:768px) and (max-width: 991.98px) {
  #slideshow-1{
    .left-side{
      left: 10%;
    }
    .right-side{
      right: 25%;
    }
  }

  #slideshow-2{
    .left-side{
      left: -3%;
    }
    .right-side{
      right: -27%;
    }
  }
  .products-promotion-slick-slider .product-img img{
    width:250px;
  }
}

@media only screen and (max-width: 767.98px) {
  .products-promotion-slick-slider {
    .left-side {
      display: none;
    }
    .right-side{
      flex: 0 0 100%;
      max-width: 100%;
      flex-direction: column;
      align-items: center;
    }
    .product-img img{
      width:220px;
    }
  }
  #slideshow-1{
    .left-side{
      left: 10%;
    }
    .right-side{
      right: 25%;
    }
  }

  .product-info{
    width: 75%;
  }

   .click-know-more{
     font-size: 12pt;
   }

  #slideshow-1 .right-side, #slideshow-2 .right-side{
    right: 0;
  }

}
