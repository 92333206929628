#register-user{

  .header-page{
    margin:20px auto;
    text-align: center;
  }
  .title-page {
    @include textFormatting('Poppins-Bold', 17pt, $mainOrange, none, default);
  }

  .subtitle-page{
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
    .anchor-for-login{
      @include textFormatting('Poppins-Regular', 10pt, $mainOrange, none, pointer);
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .body-page{
    justify-content: center;
    margin-bottom:20px;

    .first-block, .second-block{
      background-color: $bgLightGray;;
      padding: 20px;
    }

    .form-horizontal{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .form-label{
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
        margin-bottom: 3px;
      }

      .form-control{
        height: 40px;
        margin:0;
        text-align: left;
        &:focus{
          box-shadow: 0 0 0 0.2rem rgb(213 122 35 / 25%);
          border-color: $mainOrange;
        }
      }

      .form-required:after{
        content: '*';
        color: $mainOrange;
        padding-left: 5px;
      }

      .form-check{
        margin: 10px 0;
        align-items: center;
        display: flex;
        width: 100%;

        input[name=terms]{
          width: 15px;
          height: 15px;
          margin-top:0;
          &:checked{
            accent-color: $mainOrange;
          }
        }

        .form-check-label{
          margin-bottom: 0;
          @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
        }

        a{
          @include textFormatting('Poppins-Bold', 10pt, $mainGray, none, pointer);
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .btn-register{
        width: fit-content;
        height: 45px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 0 auto;
        border:0;
        border-radius:0;
        background-color: $mainOrange;
        @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
        &:focus{
          outline: 0;
        }
        &:hover{
          box-shadow: 0 0 0 0.2rem rgb(154 155 158 / 25%);
          border-color: $darkGray;
          color: $mainGray;
        }
      }
    }
  }


  @media only screen and (min-width:992px){
    .first-block, .second-block{
      width:340px;
    }

    .first-block{
      margin-right: 40px;
    }
  }

  @media only screen and (max-width:991.98px) and (min-width:425px) {
    .first-block, .second-block{
      width:75%;
      margin: 0 auto;
    }
    .first-block{
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width:424.98px){
    .first-block, .second-block{
      display: block;
      margin:10px auto;
      width:90%;
    }
  }
}
