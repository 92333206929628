#account-detail{
  .form-title{
    @include textFormatting('Poppins-Bold', 13pt, $mainOrange, none, default);
  }
  .form-horizontal{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:340px;
    .form-group{
      width:100%;
    }
    .form-label{
      @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
      margin-bottom: 0;
    }

    .form-control{
      height: 40px;
      margin:0;
      text-align: left;
      background-color: $lightGray;
      border: 0;
      &:focus{
        box-shadow: 0 0 0 0.2rem rgb(213 122 35 / 25%);
        border-color: $mainOrange;
      }
    }

    .form-required:after{
      content: '*';
      color: $mainOrange;
      padding-left: 5px;
    }

    .form-check{
      margin: 10px 0;
      align-items: center;
      display: flex;
      width: 100%;

      input[name=terms]{
        width: 15px;
        height: 15px;
        margin-top:0;
        &:checked{
          accent-color: $mainOrange;
        }
      }

      .form-check-label{
        margin-bottom: 0;
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
      }

      a{
        @include textFormatting('Poppins-Bold', 10pt, $mainGray, none, pointer);
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .btn-update{
      width: fit-content;
      height: 40px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 auto;
      border:0;
      border-radius:0;
      background-color: $mainOrange;
      @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
      &:focus{
        outline: 0;
      }
      &:hover{
        box-shadow: 0 0 0 0.2rem rgb(154 155 158 / 25%);
        border-color: $darkGray;
        color: $mainGray;
      }
    }
  }
}
