ol.breadcrumb{
  background-color: transparent;
  margin-bottom:0;
  margin-top: 20px;
  min-height: 40px;
  align-items: flex-end;
  padding: 0;

  li, li.active{
    @include textFormatting('Roboto-Regular', 10pt, $mainGray, uppercase, default);
  }
  @media only screen and (max-width: 991.98px) {
    justify-content: center;
  }
}

@media only screen and (max-width:767.98px) {
  nav[aria-label="breadcrumb"]{display: none;}
}
