#article-funded-projects {
  min-height: 54vh;

  .article-image-countent {
    img {
      width: 100%;
      max-width: fit-content;
    }
  }

  .article-title {
    text-align: center;
    font-family: 'Poppins-Bold';
    font-size: 17pt;
    color: #FF7700;
    text-transform: none;
    cursor: default;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid;
  }

  .article-description {
    margin: 20px 0;
    font-family: "Poppins-Regular";
    font-size: 12pt;
    color: #3b3b3b;
    text-transform: none;
    cursor: default;
    a{
      width: auto;
      color: black;
      text-decoration: 1px solid black;
    }

    .funded-image-conent{
      display: flex;
      max-width: 400px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      a{
        width: 29%;
      }
      img{
        width: 100%;
      }
    }
  }
}


@media (max-width: 576px) {
  #article-funded-projects {

    .article-image-countent {
      display: flex;
      justify-content: center;

    }
  }
}
