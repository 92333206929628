#article-contatos{
  margin-top: 2rem;
  .map-of-contact {
    display: block;
    height: 300px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom:20px;
  }

  .left-side,
  .right-side{
    flex: 0 0 50%;
    max-width: 50%;
  }

  .info-contacts{
    @include textFormatting('Poppins-Regular', 12pt, $mainGray, none, default);
    margin:0;
    width:fit-content;
    display: flex;
    &:nth-child(2n){
      margin-bottom:10px;
    }
  }

  a.info-contacts{
    cursor:pointer;
    &:hover{
      text-decoration: none;
      color:$mainOrange;
    }
  }

  .left-side {
    .icons-social-media {
      justify-content: flex-start;
      margin-right: 10px;

      .icon {
        color: $mainGray;
        font-size: 14px;

        &:nth-child(2n) {
          margin: 0 20px;
        }

        &:hover {
          color: $mainOrange;
        }
      }
    }
  }

  .right-side{
    .title-form{
      @include textFormatting('Poppins-Bold', 17pt, $mainOrange, uppercase, default);
    }
    .description-form{
      margin:20px 0 40px 0;
      @include textFormatting('Poppins-Regular', 12pt, $mainGray, none, default);
    }
    #form_contact{
      .form-check{
        display: flex;
        #contact_accept_terms {
          cursor:pointer;
          &:checked {
            accent-color: $mainOrange;
          }
        }
      }
      label {
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
      }
      label a.page-terms{
        @include textFormatting('Poppins-Regular', 10pt, $mainOrange, none, pointer);
      }

      #contact_accept_terms-error{
        color: $mainOrange;
        padding-right: 5px;
      }

      label.form-required:after{
        content: '*';
        color: $mainOrange;
        padding-left: 5px;
      }

      .form-group {
        input, textarea {
          border: 0;
          background-color: $lightGray;
          text-align: left;
          @include textFormatting('Poppins-Regular', 12pt, $mainGray, none, text);
          &:focus{
            box-shadow: 0 0 0 0.2rem rgb(213 122 35 / 25%);
          }
        }

        input {
          height: 40px;
        }

        textarea {
          resize: none;
          height: 100px;
        }
      }

      .action-to-use{
        display: flex;
        margin:20px 0 50px 0;
      }

      .btn-clear-form,
      .contact-submit-button{
        width:200px;
        height:40px;
        border-radius:5px;
        border:0;
        @include textFormatting('Poppins-Bold', 12pt, $White, uppercase, pointer);
        align-items: center;
        justify-content: center;
        display: flex;
        &:focus{
          outline: 0;
        }
      }

      .btn-clear-form{
        background-color: $darkGray;
        margin-right: 20px;
        &:hover{
          box-shadow: 0 0 0 0.2rem rgb(154 155 158 / 25%);
          border-color: $darkGray;
          color: $mainGray;
        }
      }

      .contact-submit-button{
        background-color: $mainOrange;
        &:hover{
          box-shadow: 0 0 0 0.2rem rgb(154 155 158 / 25%);
          border-color: $darkGray;
          color: $mainGray;
        }
      }
    }
  }

  @media only screen and (max-width:991.98px){
    .left-side,
    .right-side{
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 30px;
    }
    .right-side .title-form{
      margin-top:40px;
    }
  }

  @media only screen and (max-width:767.98px){
    .right-side #form_contact .action-to-use{
      display: block;
      .btn-clear-form{
        margin-bottom: 20px;
      }

      .btn-clear-form,
      .contact-submit-button{
        width: 100%;
        min-width: 260px;
      }
    }
  }
}
