.product-info-detail-block{
  height:120px;
  .anchor-product-title:hover{
    text-decoration: none;
    .product-title{
      @include textFormatting('Roboto-Bold', 10pt, $Black, uppercase, pointer);
    }
  }

  .product-title{
    @include textFormatting('Roboto-Regular', 10pt, $Black, uppercase, pointer);
  }

  .product-excerpt{
    @include textFormatting('Poppins-Bold', 7pt, $Black, uppercase, default);
  }

  .product-category{
    @include textFormatting('Roboto-Regular', 10pt, $darkGray, uppercase, pointer);
  }

  .product-price{
    @include textFormatting('Roboto-Slab-Black', 15pt, $mainOrange, uppercase, default);
  }
}
