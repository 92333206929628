.featured-product-card{
  width:100%;
  height: 320px;
  border:1px solid $lightGray;
  justify-content: center;
  align-items: center;
  display: flex;
  a {
    text-decoration: none;
  }
}

.featured-product-img {
  width:150px;
  margin: auto;
  display: block;
}

.featured-product-info{
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 120px;
  justify-content: flex-end;
  .product-category {
    @include textFormatting('Roboto-Regular', 10pt, $darkGray, uppercase, pointer);
  }
  .product-title {
    @include textFormatting('Poppins-Bold', 14pt, $mainGray, uppercase, pointer);
  }
  .product-price {
    @include textFormatting('Roboto-Slab-Black', 20pt, $mainOrange, uppercase, pointer);
  }
}

@media only screen and (max-width:539.98px) {
  .featured-product-card{
    height: 240px;
  }

  .featured-product-img{
    width: 100px;
  }

  .featured-product-info{
    height: 100px;
    .product-category {
     font-size: 7pt;
    }
    .product-title {
      font-size: 10pt;
    }
    .product-price {
      font-size: 14pt;
    }
  }
}
