.loading-page{
  //min-height: calc(100vh - 541px);
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    border: 14px solid $lightGray;
    border-top: 14px solid $mainOrange;
    border-bottom: 14px solid $mainOrange;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
.slideshow-products{
  height: 395px;
}

.block-products{
  height: 745px;
}

.block-featured-products{
  height: 460px;
}

.detail-product{
  height: 860px;
}

.loading-checkout{
  height: calc(100vh - 410px);
}
