.pub-fullscreen {
  .img-pub {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 360px;
    position: relative;
  }

  .filter-pub {
    width: 100%;
    height: inherit;
    mix-blend-mode: multiply;
    opacity: 0.6;
    display: flex;
    align-items: center;
    position: absolute;
  }

  .info-pub {
    width: 320px;
    height: 360px;
    margin: auto 40px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .type-pub {
      @include textFormatting('Poppins-Bold', 14pt, $White, none, default);
    }

    .title-pub {
      margin: 0;
      @include textFormatting('Poppins-Bold', 24pt, $White, none, default);
    }

    .description-pub {
      @include textFormatting('Roboto-Regular', 10pt, $White, none, default);
      margin-top: 35px;
      margin-bottom: 115px;
    }

    .anchor-to-pub {
      span {
        @include textFormatting('Poppins-Bold', 14pt, $White, none, pointer);

        &:before {
          content: '>';
          padding-right: 10px;
        }

        &:hover {
          color: $mainOrange;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .pub-fullscreen {
    padding: 0;
    margin-bottom: 20px;

    .filter-pub {
      clip-path: polygon(0% 0%, 93% 0%, 67% 100%, 0% 100%);
    }

    .img-pub {
      height: 410px;
    }
    .info-pub{
      width: 260px;
      height: 410px;
      margin: auto 1rem;
    }
  }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  .pub-fullscreen {
    padding: 0;

    .filter-pub {
      clip-path: polygon(0% 0%, 60% 0%, 43% 100%, 0% 100%);
    }
  }
}

@media only screen and (min-width: 992px) {
  .pub-fullscreen .filter-pub {
    clip-path: polygon(0% 0%, 55% 0%, 45% 100%, 0% 100%);
  }
}
