.product-toast {
  position: fixed;
  width: 300px;
  height: 150px;
  top: 70px;
  right: 10px;
  z-index: 8;

  .toast-body {
    display: flex;

    .product-image {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }

    .detail-product {
      display: flex;
      flex-direction: column;

      span.product-name {
        @include textFormatting('Roboto-Bold', 10pt, $mainGray, uppercase, default);
      }

      span:not(.product-name) {
        @include textFormatting('Roboto-Regular', 8pt, $mainGray, uppercase, default);
      }
    }
  }
  .danger{
    background-color: #f3ccd1;
  }
}

.alert-toast {
  position: fixed;
  width: 280px;
  top: 70px;
  right: 10px;
  z-index: 8;

  .toast-body {
    display: flex;
    @include textFormatting('Roboto-Bold', 10pt, $mainGray, uppercase, default);
  }
}
