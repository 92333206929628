.offer-product-block{
  margin-bottom:20px;
  .title-section{
    @include textFormatting('Roboto-Slab-Black', 15pt, $mainOrange, uppercase, default);
    margin: 10px 0 15px 0;
  }

  .all-products{
    display: flex;
  }

  .offer-product-info{
    width:260px;
    height:120px;
    display: flex;
    @import "product-img";
    .product-img-block {
      border: 1px solid $darkGray;
      width: 120px;
      position: relative;
      .product-img {
        width: 100px;
        padding: 10px;
      }
    }

    .col-icons{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      right: 10px;
      bottom: 10px;
      .video-product, .like-in-product{
        width: 20px;
      }
      .like-in-product{
        cursor:pointer;
      }
    }

    .info-product{
      padding-left:10px;
    }
    .product-category{
      @include textFormatting('Roboto-Regular', 8pt, $darkGray, uppercase, pointer);
    }

    .product-name{
      @include textFormatting('Roboto-Bold', 10pt, $Black, uppercase, pointer);
    }

    .product-excerpt{
      @include textFormatting('Poppins-Regular', 7pt, $Black, uppercase, default);
    }

  }
}
