#newsCategory{
  .header-page{
    border-bottom:1px solid $mainOrange;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .title-page{
      text-align: center;
      @include textFormatting('Poppins-Bold', 17pt, $mainOrange, none, default);
    }
  }

  .body-page{
    .article-info{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .article-date{
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, uppercase, default);
      }
      .article-title{
        @include textFormatting('Poppins-Bold', 17pt, $mainGray, none, default);
        margin:5px 0 0 0;
      }
      .article-excerpt{
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
        margin:10px 0;
      }
      .article-read-more{
        @include textFormatting('Poppins-Bold', 10pt, $mainOrange, uppercase, pointer);
      }
    }
    @media only screen and (min-width:768px){
      .each-article{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        margin: 20px 0;
        &:not(:last-of-type){
          border-bottom: 1px solid $mainOrange;
          padding-bottom: 20px;
        }
        &:nth-child(odd){
          .article-img{ order:1; }
          .article-info{
            order:2;
            padding: 20px 0 20px 30px;
          }
        }
        &:nth-child(even){
          .article-img{ order:2;}
          .article-info{
            order:1;
            padding: 20px 30px 20px 0;
          }
        }
      }

      .article-img, .article-info{
        width:50%;
      }

      .img-clip{
        clip-path: polygon(12.7% 0, 100% 0%, 87.3% 100%, 0% 100%);
        background-repeat: no-repeat;
        height: 225px;
        background-position: center;
        background-size: cover;
      }
    }
    @media only screen and (max-width:767.98px){
      .each-article{
        margin:20px 0;
        &:not(:last-of-type){
          border-bottom: 1px solid $mainOrange;
          padding-bottom: 20px;
        }
      }

      .article-img, .article-info{
        width:100%;
      }
      .article-info{
        padding-top:20px;
      }

      .img-clip{
        height: 155px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
  }
}
