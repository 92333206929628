#order-history{
  .title-page{
    @include textFormatting('Poppins-Bold', 13pt, $mainOrange, none, default);
  }

  .header-order{
    display:flex;
    align-items: center;
    justify-content: space-between;

    &:first-of-type{
      padding-bottom:10px;
    }

    &:not(:first-of-type){
      border-block: 1px solid $lightGray;
      padding:10px 0;
    }

    .left-side, .right-side{
      display:flex;
      justify-content: center;
      flex-direction: column;
    }

    .left-side {
      align-items: flex-start;
    }

    .right-side{
      align-items: flex-end;
    }
  }

  .info-order{
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
  }

  .status-order{
    margin-top:20px;
    @include textFormatting('Poppins-Bold', 10pt, $Green, none, default);
  }

  .show-more-detail{
    width: fit-content;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px auto;
    border:0;
    background-color: $mainOrange;
    @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
    &:focus{
      outline: 0;
    }
    &:hover{
      box-shadow: 0 0 0 0.2rem rgb(154 155 158 / 25%);
      border-color: $darkGray;
      color: $mainGray;
    }
  }

  .first-block{
    display:flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom:1px solid $lightGray;
    padding:10px 0;
  }

  .shipping-information, .billing-information{
    display:flex;
    flex-direction: column;
  }

  .title-section{
    @include textFormatting('Poppins-Bold', 12pt, $mainOrange, uppercase, default);
  }

  .second-block{
    margin-top:10px;
  }

  .all-products{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap:20px;
  }

  .each-product{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $lightGray;
    &:hover{
      text-decoration: none;
      cursor:pointer;
      .info-name-product{
        text-decoration: underline;
        color:$mainGray;
        cursor:pointer;
      }
    }
    img{
      width:80px;
      height:80px;
      object-fit: cover;
      margin-bottom:5px;
    }
  }

  .info-detail-product{
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .info-cat-product{
    @include textFormatting('Poppins-Regular', 8pt, $mainGray, none, default);
  }

  .info-name-product{
    @include textFormatting('Poppins-Bold', 10pt, $mainGray, none, default);
  }

  .info-product{
    @include textFormatting('Poppins-Regular', 8pt, $mainGray, none, default);
  }

  .third-block {
    border-top:1px solid $lightGray;
    margin: 20px 0;
    padding: 20px 0;
  }

  .info-payment-detail{
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
  }


  .table-orders{
    width:50%;
    thead th, tbody th, tfoot th {
      border:0;
    }
    thead tr{
      @include textFormatting('Poppins-Bold', 10pt, $mainGray, none, default);
    }
    tbody {
      border-top: 1px solid $mainGray;
      tr {
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
      }
    }
    tfoot tr{
      @include textFormatting('Poppins-Bold', 10pt, $mainGray, none, default);
      td:first-of-type{
        text-align: right;
      }
    }
  }

  @media only screen and (max-width:992.98px) and (min-width: 768px){
    .table-orders{
      width:75%;
    }
  }

  @media only screen and (max-width:767.98px){
    margin-top: 20px;
    .first-block{
      flex-direction: column;
    }
    .shipping-information{
      margin-bottom:10px;
    }
    .all-products{
      grid-template-columns: 1fr 1fr 1fr;
    }
    .table-orders{
      width:100%;
    }
  }
}
