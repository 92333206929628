.out-semaphore{
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .semaphore{
    border-radius:50%;
    width: 15px;
    height: 15px;
  }

  .available{
    background-color: $Green;
  }

  .partially-available{
    background-color: $mainOrange;
    opacity:0.5;
  }

  .unavailable{
    background-color: $darkGray;
  }

  .title-semaphore{
    margin-left:10px;
    height: 15px;
    @include textFormatting('Roboto-Regular', 12pt, $mainGray, none, default);
  }
}
