#auxiliary-modal{
  .email-for-stock{
    display: flex;
    flex-direction: column;
    margin: 20px 0 0 0;
    .form-label{
      @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
      margin-bottom: 3px;
    }

    input {
      height: 40px;
      border: 0;
      background-color: $lightGray;
      text-align: left;
      outline: 0;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgb(213 122 35 / 25%);
        border-color: $mainOrange;
        outline: 0;
      }
    }
  }

  .btn-cancel{
    background-color: $mainGray;
  }

  .btn-submit{
    background-color: $mainOrange;
  }

  .btn-cancel, .btn-submit{
    @include textFormatting('Poppins-Bold', 10pt, $White, none, pointer);
  }

  .modal-body .message{
    @include textFormatting('Poppins-Regular', 8pt, $Green, none, pointer);
  }
}

