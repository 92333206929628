.additional-product-card{
  .product-detail-card{
    display: flex;
    flex-direction: row;
    border:0;
    padding-left:0;
   .product-img-block{
      background-color: $White;
      width: 150px;
      height: 150px;
      img{
        border: 1px solid $darkGray;
       width: 150px;
        height: 150px;
      }
      .col-icons{
        right: 225px;
        bottom: 30px;
      }
    }
  }

  .right-side-card{
    padding-left: 20px;
  }
}
@media only screen and (max-width:424.98px) {
  .additional-product-card {
    .right-side-card{
      padding-left: 10px;
    }

    .product-detail-card .product-img-block {
      width: 100px;
      height: 100px;

      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
