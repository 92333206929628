.checkout-page{
  .checkout-alert{
    width: 400px;
    position: fixed;
    z-index: -1;
    right: 20px;
  }


  .page-header{
    display: block;
    margin:40px auto 0 auto;
    text-align: center;
    .title-page {
      @include textFormatting('Poppins-Bold', 17pt, $mainOrange, none, default);
    }
  }


  .first-block, .second-block, .third-block{
    background-color: $bgLightGray;
    padding: 20px;
  }

  .second-block{
    .promotional-code-input{
      border:1px solid $darkGray;
      width: 80%;
      height:35px;
      margin:0;
      @include textFormatting('Roboto-Regular', 8pt, $mainGray, uppercase, text);
      text-align: left;
      padding-left: 10px;
    }

    .promotional-code-application{
      background-color: #b4b4b4;
      width: fit-content;
      height:35px;
      padding: 0 20px;
      border:0;
      @include textFormatting('Poppins-Bold', 10pt, $lightGray, uppercase, default);
    }
  }

  .store-url{
    text-decoration: none;
    @include textFormatting('Poppins-Regular', 10pt, $mainOrange, none, pointer);
  }
  .title-block{
    @include textFormatting('Poppins-Bold', 14pt, $mainOrange, uppercase, default);
  }

  .separator-block{
    border-top-color: $darkGray;
    margin-top: 0.5rem;
  }

  .form-group, .form-row{
    margin-bottom:0.75rem;
  }

  .form-label{
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
    margin-bottom: 3px;
  }

  .form-control{
    height: 40px;
    margin:0;
    text-align: left;
    &:focus{
      box-shadow: 0 0 0 0.2rem rgb(213 122 35 / 25%);
      border-color: $mainOrange;
    }


  }

  .form-required:after{
    content: '*';
    color: $mainOrange;
    padding-left: 5px;
  }

  .promotional-code-group{
    display: flex;
    margin:20px 0;
  }

  #select-store{
    width: 100%!important;
    height: 40px;
    margin:0;
    text-align: left;
    &:focus{
      box-shadow: 0 0 0 0.2rem rgb(213 122 35 / 25%);
      border-color: $mainOrange;
    }
  }

  #select-store > * {
    text-transform: lowercase;
  }
  .select2{
    width: 100%!important;
    height: 40px;
  }

  .new-address{
    @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, pointer);
  }

  .choose-delivery{
    margin-right:1rem;
    label{
      cursor:pointer;
    }

    input[type="radio"]{
      cursor:pointer;
      accent-color: $mainOrange;
    }
  }

  .second-block{
    .inputGroup {
      background-color: #fff;
      display: block;
      margin: 5px 0;
      position: relative;

      label {
        padding: 10px 20px;
        width: 100%;
        display: block;
        text-align: left;
        color: $mainGray;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition: color 200ms ease-in;
        overflow: hidden;

        &:before {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          content: '';
          background-color: $darkGray;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale3d(1, 1, 1);
          transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
          opacity: 0;
          z-index: -1;
        }

        &:after {
          width: 20px;
          height: 20px;
          content: '';
          border: 0px solid #D1D7DC;
          background-color: #fff;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
          background-repeat: no-repeat;
          background-position: 2px 3px;
          border-radius: 50%;
          z-index: 2;
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          transition: all 200ms ease-in;
        }
      }

      input:checked ~ label {
        color: #fff;

        &:before {
          transform: translate(-50%, -50%) scale3d(56, 56, 1);
          opacity: 1;
        }

        &:after {
          background-color: $mainOrange;
          border-color: $mainOrange;
        }
      }

      input {
        width: 32px;
        height: 32px;
        order: 1;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        visibility: hidden;
      }
    }
  }

  .product-list{
    width:100%;
    .product-summary-table{
      border-collapse: collapse;
      width: 100%;
      height: 300px;
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
    thead{
      border-bottom:1px solid $darkGray;
      position: sticky;
      top: 0;
      background-color: $White;
      height: 30px;
      th{
        @include textFormatting('Poppins-Bold', 8pt, $mainGray, none, default);
        width: calc(100% / 10);
      }
    }
    tbody{
      background-color: $White;
      tr {
        border-bottom:1px solid $darkGray;
        &:nth-of-type(odd){
          background-color: $lightGray;
        }
        td {
          margin:5px 0;
          a , h1{
            @include textFormatting('Poppins-Regular', 8pt, $mainGray, none, default);
          }
          .old-price{
            text-decoration: line-through;
          }
        }
      }
    }

    .product-image{
      width:50px;
      height:50px;
      object-fit: cover;
    }

    .info-product .product-desc-block{
      display: block;
    }
  }


  .invoice-summary{
    margin-top:10px;
    .invoice-summary-info, .invoice-summary-total{
      display: grid;
      padding: 5px 0;
      flex-direction: column;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 80px;
    }

    .invoice-summary-info{
      border-bottom: 1px solid $lightGray;
      span{
        @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
      }
    }

    .invoice-summary-total{
      span{
        @include textFormatting('Poppins-Bold', 10pt, $mainGray, none, default);
      }
    }
  }

  .opinion-group{
    margin: 10px 0;
    .form-label{
      @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
      margin-bottom: 3px;
    }
    .textarea-input{
      height:80px;
      resize: none;
    }
  }

  .acceptance-of-terms{
    display: flex;
    align-items: center;
    padding: 0;
    .form-check-label{
      @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, default);
      margin-left: 22px;
    }
    .form-check-label a{
      @include textFormatting('Poppins-Bold', 10pt, $mainGray, none, pointer);
      &:hover {
        text-decoration: underline;
      }
    }
    .form-check-input{
      width:15px;
      height:15px;
      margin:0;
      cursor:pointer;
      &:checked{
        accent-color: $mainOrange;
      }
    }
  }

  .final-step-warning{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 40px;

  }
  .final-step{
    width: fit-content;
    height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0 auto;
    border:0;
    background-color: $mainOrange;
    @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
    &:focus{
      outline: 0;
    }
    &:hover{
      box-shadow: 0 0 0 0.2rem rgb(154 155 158 / 25%);
      border-color: $darkGray;
      color: $mainGray;
    }
    &:disabled{
      cursor: no-drop;
      background-color: $mainOrange;
      opacity: 0.5;

    }
  }
}

@media only screen and (min-width:992px){
  .page-body form{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
  }

  .checkout-page .second-block{
    margin:0 30px;
  }
}

@media only screen and (max-width:991.98px) and (min-width:425px) {
  .page-body form{
    display: block;
    margin:40px auto;
    width:75%;
  }

  .checkout-page .second-block{
    margin:30px 0;
  }
}

@media only screen and (max-width:424.98px){
  .page-body{
    display: block;
    margin:20px auto;
    width:90%;
  }
  .checkout-page .second-block{
    margin:20px 0;
  }
}
