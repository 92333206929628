#article{
  min-height:90vh;
  .article-title{
    margin: 20px 0;
    @include textFormatting('Poppins-Bold', 17pt, $mainOrange, none, default);
  }

  .article-content{
    margin: 10px 0 20px 0;
    @include textFormatting('Poppins-Regular', 12pt, $mainGray, none, default);
    p { margin:0;}
    strong {
      @include textFormatting('Poppins-Bold', 12pt, $mainOrange, none, default);
    }
  }
}
