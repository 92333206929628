@import "../global";
@import "global";

// Variables
@import 'variables';


// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~slick-carousel/slick/slick';

@import "components/dynamic-table";
@import "components/menu/menu-horizontal";
@import "components/menu/search-input-block";
@import "components/links-social-media";
@import "components/menu/menu-vertical";
@import "components/footer/footer";
@import "components/homepage/categories";
@import "components/section-identification";
@import "components/homepage/featured-products";
@import "components/product-presentation-card";
@import "components/product-detail-card";
@import "components/detail-product/product-slick-slider";
@import "components/detail-product/technical-details-navbar";
@import "components/product/breadcrumb";
@import "components/homepage/downloads";
@import "components/banner-pub";
@import "components/homepage/slideshow-products";
@import "components/homepage/lastest-news";
@import "components/detail-product/related-products-slick-slider";
@import "components/payments";

@import "icones/menu-icon";
@import "icones/close-icon";

@import "pages/search";
@import "pages/product";
@import "pages/categoryitems";
@import "pages/categoryitems-sobre-a-dormak";
@import "pages/article-contactos";
@import "pages/article-agentes";
@import "pages/article";

/*-- V2 --*/
@import "components/product-card-components/sales-condition-type";
@import "components/product-card-components/product-img";
@import "components/product-card-components/product-info";
@import "components/product-card-components/options-to-buy-the-product";
@import "components/product-card-components/check-availability";
@import "components/product-card-components/product-detail-info";
@import "components/input-counter";
@import "components/additional-product-card";
@import "components/menu/categories-products";
//@import "components/menu/user-login-block";
@import "components/detail-product/product-slideshow-fullscreen";
@import "pages/shopping-cart";
@import "components/product-toast";
@import "pages/checkout";
@import "pages/register";
@import "pages/account";
@import "components/reserved-area/information";
@import "components/reserved-area/account-detail";
@import "components/reserved-area/order-history";
@import "components/reserved-area/favorites-list";
@import "components/reserved-area/shipping-addresses";
@import "pages/categoryitems-noticias";
@import "pages/article-noticias-detail";
@import "components/homepage/slideshow-news";
@import "components/homepage/featured-products-slick-slider";
@import "components/auxiliary-modal";

@import "components/Vue/click-counter";
@import "pages/login";
@import "components/homepage/product-promotions-slick-slider";
@import "components/product-card-components/offer-product";
@import "components/loading";
@import "pages/recovery-password";
@import "pages/article-proejetos-finaciados";
@import "components/banner-pub-fullscreen";

#vertical-navbar {
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.7) transparent;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    border: 3px solid transparent;
  }
}

  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.7) transparent;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    border: 3px solid transparent;
  }

  .like-in-product.liked{
    path{
      fill : #FF7700;
      stroke: #FF7700;
    }
  }

.select2-container--default .select2-selection--single{
  padding: 0.375rem 0.75rem;
  height: 40px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
  height: 40px;
}



//Projetos financiados
.funded-projects{
  z-index: 90000;
  background-color: $mainGray;
  left: 30px;
  position: fixed;
  width: 100%;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  align-items: stretch;
  border-radius: 20px;
  padding: 1.5em;
  .close{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
  }
  span{
    color: white;
    font-size: 9px;
    display: block;
    margin-bottom: 1em;
  }
  .funded-imgs-content{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;

    a{
      width: 28%;
    }
  }
}
@media (max-width: 481px){
  .funded-projects {
    left: 0;
  }
}

.grecaptcha-badge { visibility: hidden; }
