#categoryItems, #categoryItemsPromotions{
  min-height: 100vh;
  .head-categoryItems{
    position: relative;
    .breadcrumb{
      margin-bottom: 20px;
    }
  }

  .filters-nav{
    display: flex;
    align-items: center;
    .open-filters{
      @include textFormatting('Poppins-Bold', 12pt, $mainOrange, uppercase, pointer);
      &:before{
        content:'>';
        padding-right: 5px;
        @include textFormatting('Poppins-Bold', 12pt, $mainOrange, uppercase, pointer);
      }
    }
    .order-icom-stock{
      width: 35px;
      margin: 0;
    }
  }

  .filter-info{
    opacity:0;
    padding-top: 0;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 60%);
    .close-icon{
      padding-left: 20px;
      width: 20px;
      height: 20px;
    }
  }

  .filter-content{
    display: none;
    column-gap: 20px;
    .form-check{
      padding-left:0;
    }
    .filter-title{
      @include textFormatting('Poppins-Bold', 10pt, $mainGray, uppercase, pointer);
    }
    .form-check-label{
      @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, pointer);
    }
    .form-check-input {
      cursor:pointer;
      &:checked {
        accent-color: $mainOrange;
      }
    }
  }

  .filter-content.container input[type=checkbox] {
    position: inherit;
    margin: 0;
    padding: 0;
    width: 20px;
  }

  .order-content span{
    width: 20px;
    display: block;
    margin: 0 10px;
    cursor:pointer;
  }

  .order-content img{
    width: 100%;
  }
  .order-content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15px;
    z-index: 9;
  }

  .first-block-products,
  .second-block-products,
  .third-block-products {
    display: grid;
    align-items: start;
  }

  .color-line {
    border-top:1px solid $mainOrange;
    margin:0;
  }

  .product-load-block {
    margin:40px auto;
  }

  .btn-to-load-products{
    height:60px;
    width:345px;
    background-color: $mainOrange;
    @include textFormatting('Poppins-Bold', 14pt, $White, uppercase, pointer);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media only screen and (max-width:574.98px){
      width: fit-content;
      padding: 10px 15px;
    }
  }

  @media only screen and (min-width:992px) {
    .first-block-products,
    .second-block-products,
    .third-block-products{
      grid-gap: 40px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin:20px 0;
    }

    .filter-nav-mobile{
      display: none;
    }

    .filter-content{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      margin: 10px 30px;
    }
  }

  @media only screen and (max-width:991.98px) and (min-width:768px){
    .first-block-products,
    .second-block-products,
    .third-block-products{
      grid-gap: 40px;
      grid-template-columns: 1fr 1fr;
      padding: 20px 80px;
    }

    .filter-nav-mobile{
      display: none;
    }

    .filter-info{
      margin-top:20px;
    }

    .filter-content{
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 10px 20px;
    }
  }

  @media only screen and (max-width:767.98px) {
    .head-categoryItems{
      padding:0;
    }
    .first-block-products,
    .second-block-products,
    .third-block-products{
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
      margin:20px 0;
      @media only screen and (max-width:574.98px){
        grid-gap: 15px;
      }
      .related-product-card{
        height:280px;
      }
    }

    .filter-nav-mobile{
      display: block;
      .section-identification-body{
        width:100%;
      }
    }

    .filters-nav {
      padding-top: 20px;
      .section-identification-body {
        display: none;
      }
    }

    .filter-content{
      grid-template-columns: 1fr 1fr;
      margin: 10px 20px;
    }

    .filter-info{
      margin-top:20px;
    }
    .order-content{
      right:0;
    }
  }
}
