.product-detail-card{
  .product-img-block{
    align-items: flex-end;
    justify-content: center;
    display: flex;
    .product-img{
      width: 180px;
      height: 180px;
      object-fit: cover;
    }

  }

  @media only screen and (min-width: 350px) and (max-width: 574.98px) {
    .product-img-block {
      .product-img {
        width: 140px;
        height: 140px;
      }
    }
  }

  @media only screen and (max-width: 349.98px) {
    .product-img-block {
      .product-img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
