.dataTables_wrapper{clear:both}
.dataTables_length{ float:left}
.dataTables_filter{ float:right}
.dataTables_info{ float:left}
.dataTables_paginate { float:right}
.dynamic-table{
  clear: both;
  .pcs:after {
    content: " pcs";
  }

  .cur:before {
    content: "$";
  }

  .per:after {
    content: "%";
  }

  * {
    box-sizing: border-box;
  }

    width: 100%;
    thead {
     background-color: #9e9e9e;
     &:hover{
       background-color: #d7d7d7;
      }
    }
    th {
      text-align: left;
      border-bottom: 1px solid #ccc;

    }
    tbody{
      background-color: #FFFFFF;
      tr {
        &.even {background: #FAFAFA}
        &.odd {background: #FDFDFD}
        &:hover{ background: #d7d7d7}
      }
    }
    th,td {
      padding: 0.4em;
      color: #58585a;
    }

}

