.shopping-cart, #app-shopping-cart{
  .shopping-cart-alert{
    width: 400px;
    position: fixed;
    z-index: -1;
    right: 20px;
  }
  .page-header{
    display: block;
    margin:40px auto 0 auto;
    text-align: center;
    .title-page {
      @include textFormatting('Poppins-Bold', 17pt, $mainOrange, none, default);
    }
    .subtitle-page{
      @include textFormatting('Poppins-Regular', 10pt, $mainGray, none, pointer);
      margin: auto;
      width: fit-content;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .page-body {
    .list-of-chosen-products{
      width:100%;
      thead{
        height: 40px;
        border-bottom:1px solid $darkGray;
        th{
          @include textFormatting('Poppins-Bold', 10pt, $mainGray, none, default);
        }
      }
      tbody{
        border-bottom:1px solid $darkGray;
        tr {
          td {
            margin:5px 0;
          }
        }
      }

      .info-product{
        &.offer-product-line{
          padding-left: 80px;
        }
        @media only screen and (max-width:991.98px){
          &.offer-product-line{
            padding-left: 30px;
          }
        }
        display: flex;
        flex-direction: row;
        .product-image{
          width: 60px;
          height: 60px;
          margin-right: 15px;
          cursor:pointer;
        }
        .product-description{
          @include textFormatting('Roboto-Regular', 10pt, $mainGray, uppercase, pointer);
          &:hover{
            font-family: 'Roboto-Bold';
          }
        }
        .breadcrumb-product{
          @include textFormatting('Roboto-Regular', 8pt, $mainGray, uppercase, pointer);
        }
        a:hover{
          text-decoration: none;
        }
        .product-desc-block{
          display: block;
        }
      }
      .product-price{
        @include textFormatting('Roboto-Regular', 10pt, $mainGray, uppercase, default);
      }

      .discounted-full-price, .product-discount-price{
        @include textFormatting('Roboto-Regular', 8pt, $mainGray, uppercase, default);
        text-decoration: line-through;
      }
      .product-total{
        @include textFormatting('Roboto-Regular', 10pt, $mainGray, uppercase, default);
      }

      .actions-to-user{
        cursor:pointer;
        &:hover{
          color: $Red;
        }
      }
    }
  }

  .page-footer{
    background-color: $lightGray;
    width: 100%;
    margin: 20px 0 20px auto;
    padding: 20px;
    .detail-title{
      width: 100%;
      height:40px;
      text-align: left;
      @include textFormatting('Poppins-Bold', 12pt, $mainGray, uppercase, default);
    }

    .cupon-title{
      @include textFormatting('Roboto-Bold', 10pt, $mainGray, uppercase, default);
    }

    .promo{
      display: flex;
      margin-bottom: 20px;
    }

    .promotional-code-input{
      border:1px solid $darkGray;
      width:200px;
      height:35px;
      margin:0;
      @include textFormatting('Roboto-Regular', 8pt, $mainGray, uppercase, text);
      text-align: left;
      padding-left: 10px;
     &:focus {
       outline:0;
       box-shadow: 0 0 0 0.2rem rgb(61 61 60 / 40%);
       border-color: $mainGray;
     }
    }

    .promotional-code-application{
      background-color: $mainGray;
      width: fit-content;
      height:35px;
      padding: 0 20px;
      border:0;
      @include textFormatting('Poppins-Bold', 10pt, $lightGray, uppercase, pointer);
      &:disabled{
        background-color: $darkGray;
        cursor:default;
      }
    }

  .txt-shipping-method > *{
    @include textFormatting('Roboto-Regular', 8pt, $mainGray, none, default);
    margin-bottom: 0;
  }

    .detail-total-purchase {
      .first-block, .second-block{
        display: grid;
        padding: 5px 0;
        flex-direction: column;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 65px;
      }
      .first-block span{
        @include textFormatting('Roboto-Regular', 9pt, $mainGray, uppercase, default);
      }
      .second-block span{
        @include textFormatting('Roboto-Bold', 9pt, $mainGray, uppercase, default);
      }
    }
  }

  .actions-to-continue{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .next-step{
      width: fit-content;
      height: 45px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      border:0;
      @include textFormatting('Poppins-Bold', 10pt, $White, uppercase, pointer);
      &:focus{
        outline: 0;
      }
    }

    .next-step{
      background-color: $mainOrange;
      &:hover{
        box-shadow: 0 0 0 0.2rem rgb(154 155 158 / 25%);
        border-color: $darkGray;
        color: $mainGray;
        //text-decoration: none;
      }
    }
  }

  @media only screen and (max-width:991.98px){
    .page-footer{
      width:100%;
      .block-cupons{
        margin-top:20px;
      }
    }
  }

  @media only screen and (max-width:574.98px){
    .page-footer{
      .actions-to-continue{
        align-items: center;
        flex-direction: column;
        .next-step{
          width:250px;
        }
      }
    }
  }
}
